import React, { useEffect, useState } from "react";
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { RxDotFilled } from 'react-icons/rx';
import axios from 'axios';
import epilepsyLogo from "../../../epilepsy-awareness-symbol-pink-backdrop.png";

function App(props) {
  const [getData, setData] = useState([]);

  useEffect(() => {

    axios.get('https://getjob.stechomeyazilim.info:5101/getSliderLG/select/')
      .then(res => {
        setData(res.data.value);
        props.setIsLoading(false)
      })
      .catch(err => console.log(err));
  }, []);


  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? getData.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === getData.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  return (
    getData.length > 0 ?
      <div className='w-full h-full  relative group'>
        <img
          src={'https://letgoadmin.stechomeyazilim.info' + getData[currentIndex].Slider_Text}
          className='w-full h-full bg-center bg-cover duration-500 object-cover'></img>

        {/* Left Arrow */}
        <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
          <BsChevronCompactLeft onClick={prevSlide} size={30} />
        </div>
        {/* Right Arrow */}
        <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
          <BsChevronCompactRight onClick={nextSlide} size={30} />
        </div>
        <div className='flex justify-center'>
          {getData.map((slide, slideIndex) => (
            <div
              key={slideIndex}
              onClick={() => goToSlide(slideIndex)}
              className='text-2xl cursor-pointer '>
              <RxDotFilled />
            </div>
          ))}
        </div>
      </div> : <div className='w-full h-full  m-auto  relative group'></div>
  );
}

export default App;