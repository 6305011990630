
import React, { useEffect, useState } from "react";
import axios from 'axios';
import Navbar from "../Navbar2";
import { Link } from 'react-router-dom';
import { Breadcrumbs, Input, Button } from "@material-tailwind/react";
import Footer from "../Footer";
import LoadingSpinner from "../../LoadingSpinner";


export default function TabsComponent() {
    const [isLoading, setIsLoading] = useState(false);
    const [getEducationCategory, setEducationCategory] = useState([]);
    const [getCategoryData, setCategoryData] = useState([]);

    
    useEffect(() => {
        setIsLoading(true)
        const getCategory = async() => {
         
            try{
           //   setLoginIND(JSON.parse(localStorage.getItem('dataLoginCompany')).IND)                    

           
                axios.get(`https://getjob.stechomeyazilim.info:5101/getEducationCategory/select/`)
                .then(res => {
                    console.log("1231ccc23",res.data.value)
                    setEducationCategory(res.data.value);

                    _getCategoryData(res.data.value[0].ID,res.data.value[0].Education_ModuleCategory_Text)


                })
                .catch(err => console.log("err111",err));

        }
        catch (error) {
          console.log("errorccAS" + error)
        }
    
         
        }
    
    
        getCategory()
          },[]);

    const [getSelectCategory, setSelectCategory] = useState([]);
    const [getSelectName, setSelectName] = useState("");

    function _getCategoryData(ID,name) {
        let userID =  JSON.parse(localStorage.getItem("dataLogin")).ID

        console.log()
        axios.get(`https://getjob.stechomeyazilim.info:5101/getSelectEducationCategory/select/${ID}/${userID}`)
            .then(res => {
                setSelectName(name)

                setSelectCategory(ID)
                setCategoryData(res.data.value)

              console.log("ksşdfkmlşdcsf",res.data.value)

                setIsLoading(false)


            })
            .catch(err => console.log(err));
    }



    return (
        <div className="w-full h-full bg-[#e9e9e9]">

            <Navbar />
            <div className="mt-36  flex flex-col h-full p-10  bg-gradient-to-r from-[#d03730] to-[#eb5d5f] items-center   ">
                <h3 className=" text-4xl w-full text-center  py-3 ">
                    <span class="text-transparent bg-clip-text bg-white  font-[Gotham-Medium]">Epilepsi Eğitim
                    </span>
                </h3>
                <div className='flex justify-center'>

                    <Breadcrumbs className='bg-white'>
                        <Link to="/" className="opacity-90 text-black  font-[Gotham-Medium]">
                            Anasayfa
                        </Link>

                        <Link href="/Epilepsiegitim" className="text-black  font-[Gotham-Medium]">Epilepsi Eğitim</Link>
                    </Breadcrumbs>
                </div>
            </div>



            <div className="w-full justify-center items-center">

        

                        <div className="mt-4 grid  xxs:grid-cols-3 lg:grid-cols-5 md:grid-cols-6 w-full p-2 justify-center items-center">

                        {getEducationCategory.map((item) => {
                                    return (
                           
                                <button
                                    onClick={() => _getCategoryData(item.ID,item.Education_ModuleCategory_Text)}
                                    className={` ${getSelectCategory == item.ID ? "bg-gradient-to-r from-[#d03730] to-[#eb5d5f] text-white font-[Lato-Bold]" : "font-[Lato-Medium] bg-[#f0d6ff] text-white"} inline-block px-4 py-2  shadow w-full border-r-2`}>
                                   {item.Education_ModuleCategory_Text}
                                </button>
                          )})
                            }
                                              
                        </div>

                


                <div className="items-center grid xxs:grid-cols-2 lg:grid-cols-3 md:grid-cols-3 gap-2 m-2">
                {getCategoryData.map((item,index) => {
                                    return (
                             <div className={index % 2 == 0 ? "bg-[#eb5d5f] w-full  justify-center items-center rounded-md relative" : "bg-[#d03730] w-full h-full  justify-center items-center rounded-md relative" } >
                            
                             <div className="absolute right-0 top-0 bg-white rounded-bl-lg rounded-tr-lg shadow-xl">
                              <text className="font-[Lato-Medium]  text-center text-[#d03730] p-2">{getSelectName}</text>
                             </div>
                            
                             <div className="w-full h-98 flex justify-center items-center rounded-t-lg">
        <img
         src={'https://epilepsiadmin.stechomeyazilim.info' + item.Education_MainImage}
          alt=""
          className="w-full h-80 rounded-t-lg"
        />
                               </div>
                           

                             <div className={ index % 2 == 0 ? "justify-center items-center w-full flex p-2 bg-[#d03730] mt-3" : "justify-center items-center w-full flex p-2 bg-[#eb5d5f] mt-3"}>
                              <text className="font-[Gotham-Medium] text-center text-[white]">{item.Education_Title}</text>
                             </div>

                             <div  className="bg-[white] relative w-full justify-center items-center rounded-b-md text-center " >
                              <Link to={"/egitimdetay/" + item.Education_Title.replace('?', '').replace(/\s+/g, '-')} state={{ allData: getCategoryData, _item:item}} className="text-center justify-center items-center w-full p-3">
                                <text className="font-[Lato-Medium] text-center shadow-xl ">İNCELE</text>
                                </Link>
                                </div>

                            </div> )})
                            }
                </div>


                <Footer />


            </div>

            <LoadingSpinner show={isLoading}/> 

        </div>
    );
}