import React from "react";
import Moment from 'moment';

const ProductCard = (props) => {
  return (

<div class="relative   rounded overflow-hidden shadow-lg border-2 border-[#d03730]">


<div class=" text-center justify-center items-center  bg-[#eb5d5f]">
  <p className="font-[Lato-Medium] text-white text-center p-2 ">{props._item.Pool.Pool_Title} </p>
  <text className="font-[Lato-Medium] text-white" >{Moment(props._item.Users_CreatedDateTime).fromNow()} </text>
</div>


</div>
  );
};

export default ProductCard;

