import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faCompassDrafting, faLaptopMedical, faMotorcycle, faBabyCarriage, faCar } from "@fortawesome/free-solid-svg-icons";



import { HeartIcon } from "@heroicons/react/24/solid";
const SliderCard = (props) => {
  const backgroundImageUrl = `https://letgoadmin.stechomeyazilim.info${props._item.Advert_Category_Image}`;

  const [isLoading, setIsLoading] = useState(false);

  const [getEducationCategory, setEducationCategory] = useState([]);
  const [isFavourite, setIsFavourite] = useState(false);

  const handleFavouriteClick = () => {
    setIsFavourite(!isFavourite);
  };
  const [getCategoryData, setCategoryData] = useState([]);
  const [getAdvertCategory, setAdvertCategory] = useState([]);

  useEffect(() => {
    setIsLoading(true)
    const getCategory2 = async () => {

      try {
        //   setLoginIND(JSON.parse(localStorage.getItem('dataLoginCompany')).IND)                    


        axios.get(`https://getjob.stechomeyazilim.info:5101/getAdvertCategoryLG/select/`)
          .then(res => {
            console.log("1231ccc23", res.data.value)
            setCategoryData(res.data.value);




          })
          .catch(err => console.log("err111", err));

      }
      catch (error) {
        console.log("errorccAS" + error)
      }


    }

    getCategory2()
  }, []);


  return (
    <div className={" mb-10"} >
      <Link
        to={"/advertsubcategory/" + props._item.ID}
        state={{ _item: props._item }}   
        className="text-[#868686] h-32 w-32 relative flex flex-col justify-center items-center rounded-lg text-center"
      >
        <div
          className="h-full w-full relative flex flex-col justify-center items-center rounded-lg text-center"
          style={{
            backgroundImage: `url(${backgroundImageUrl})`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat'
          }}
        >
          {props._item.Advert_Category_Icon === 'car' ? (
            <FontAwesomeIcon icon={faCar} size={50} color={'white'} />
          ) : props._item.Advert_Category_Icon === 'house' ? (
            <FontAwesomeIcon icon={faHouse} size={50} color={'white'} />
          ) : props._item.Advert_Category_Icon === 'compass' ? (
            <FontAwesomeIcon icon={faCompassDrafting} size={50} color={'white'} />
          ) : props._item.Advert_Category_Icon === 'laptop' ? (
            <FontAwesomeIcon icon={faLaptopMedical} size={50} color={'white'} />
          ) : props._item.Advert_Category_Icon === 'motor' ? (
            <FontAwesomeIcon icon={faMotorcycle} size={50} color={'white'} />
          ) : props._item.Advert_Category_Icon === 'baby' ? (
            <FontAwesomeIcon icon={faBabyCarriage} size={50} color={'white'} />
          ) : null}
        </div>
        <p>{props._item.Advert_Category_Title}</p>
      </Link>



    </div>
  );
};

export default SliderCard;
