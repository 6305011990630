import React,{useState} from "react";
import Navbar from "../Navbar2";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import { FreeMode ,Pagination, Navigation } from "swiper";
import EpilepsiCard from "./EpilepsiCard";
import Footer from "../Footer";
import { useLocation } from "react-router-dom";
import ReactHtml from 'raw-html-react';
import base64 from 'react-native-base64'
import utf8 from "utf8";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Moment from 'moment';


const BlogDetail = () => {
  const location = useLocation();
  const state = location.state;

  const [check, setCheck] = useState(false);

  React.useEffect(() => {

   
      setCheck(true)
   
  })

  const saveEducation= async(ID) => {
    try{

     setCheck(!check)

   if(!check == true){

    
    let userID =  JSON.parse(localStorage.getItem("dataLogin")).ID
    var testDateUtc = Moment.utc(new Date());
    var localDate = Moment(testDateUtc).local();

    let favoriteData = {
      Education_ID:ID,
      Users_ID:userID,
      Created_DateTime:localDate
    }
    axios.post('https://getjob.stechomeyazilim.info:5101/postComplatedEP/send', favoriteData).then(async (res) => {
      showToast('Epilepsi Eğitim', "Başarıyla Eğitimi Tamamladınız!", 'success')

  })
}
}
catch (error) {
  console.log("errorccAS" + error)
}
  }

  const showToast= (event, value, type) => {
    if(type == "false"){
      toast.error(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });

    }else{
      toast.success(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });

    }
  
  }
  return (
    <div>
      <Navbar />
      <div className="w-full flex flex-col">
        <div className="max-w-full mt-36 flex flex-col bg-[#F7F7F7] h-full  items-center  ">
        
          <div className="w-full h-full p-2 items-center flex flex-col">
          
          
            <div className="flex flex-wrap justify-center items-center sm:px-24 pb-4">
             
          
              <text className="text-4xl font-[Lato-Bold] text-center ">
              {state._item.Education_Title}
              </text>
            </div>
            
            <div className="w-full flex justify-center relative">
              <div className="flex justify-center  pr-4">
              <img src={'https://epilepsiadmin.stechomeyazilim.info' + state._item.Users.Users_Photo} alt="" className="w-16 h-16 rounded-full" />
                <text className="text-xl p-2 font-[Lato-Medium]">{state._item.Users.Users_NameSurname}</text>
              </div>

              <div>
              <img src={'https://epilepsiadmin.stechomeyazilim.info' + state._item.Education_MainImage} className=" h-80 rounded-md" />
            </div>
            </div>
            


            <div className="border-l-8 border-[#d03730] p-3 mt-2">
                <p className="ml-2 text-[11px]">
                {state._item.Education_ShortDesc}
                </p>
              </div>


            {state._item.Education_LongDesc!= null ? <div className="bg-[#e6e6e6] justify-center items-center p-2 m-4 w-full text-center ">
            <div className="bg-[#d03730] justify-center items-center p-2 w-full rounded-lg">
            <text className="text-xl p-2 font-[Lato-Medium] text-center text-white">Eğitim Detay</text>
            </div>

            <ReactHtml className="font-[Lato-Medium]" html= {utf8.decode(base64.decode(base64.decode(state._item.Education_LongDesc)))} />
             
            </div> : null}

 
              
         
            
            <div>
            <iframe src={state._item.Education_Link} height="500" width="500"/>
            
            <div className="bg-[#d03730] rounded-b-md text-center">
            <a target="_blank" href={state._item.Education_Link} className=" border-b-2 font-[Lato-Bold] p-2 text-white text-center text-2xl">
                Tam Ekran İncele
                </a>
              
               <div className="bg-[#a896de]">
                <FormGroup className="w-full justify-center items-center">
                <FormControlLabel  onClick={()=> saveEducation(state._item.ID)} control={<Checkbox disabled={true} checked={check}/>} label={<text className="text-white text-[Lato-Medium]">Eğitimi Tamamladım</text>}  />
              </FormGroup>
             
              <text className="text-[#d03730] font-[Lato-Bold]">{Moment(state.complatedData.Created_DateTime).format('YYYY-MM-DD')} Tarihinde Tamamlandı</text>

              <div>



             </div>

             </div>

                </div>

            </div>
         
        
          </div>
        </div>
      </div>
    

      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
    </div>
  );
};

export default BlogDetail;
