import React, { useEffect } from "react";
import { GrClose } from "react-icons/gr";
import { useState } from "react";
import axios from "axios";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faHeart } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { FaHeart, FaKey } from "react-icons/fa";
import { AiFillEyeInvisible } from "react-icons/ai";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  boxShadow: 24,
};

const Autharizatiom = (props) => {
  const [forgotPassword, setForgotPassword] = useState(false);
  const [check, setCheck] = useState(false);
  const [getopenChangePasswordModalVisible, setopenChangePasswordModalVisible] = useState(false);

  const [getNewPassword, setNewPassword] = useState();


  const [passwordShown, setPasswordShown] = useState();
  const [passwordShownConfirm, setPasswordShownConfirm] = useState();

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("dataLoginEpilepsi"))
    if (data != null) {
      setCheck(true)

      setEmail(data.mail)
    } else {
      setCheck(false)
    }


  }, []);


  const showToast = (event, value, type) => {
    if (type == "false") {
      toast.error(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

    } else {
      toast.success(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

  }



  const [passwordConfirm, setPasswordConfirm] = useState();
  const [getCurrentPassword, setCurrentPassword] = useState();


  const setChangeConfirm = (value, type) => {
    if (type == "currentPassword") {
      setCurrentPassword(value)

    }
    if (type == "newPasswordConfirm") {
      setPasswordConfirm(value)

    }
    if (type == "newPassword") {
      setNewPassword(value)

    }
  }

  const _changePasswordHandle = async () => {
    try {
      let userID = JSON.parse(localStorage.getItem("dataLogin")).ID

      if (getNewPassword == passwordConfirm) {
        await axios.get(`https://getjob.stechomeyazilim.info:5101/getUserLoginEpilepsi/select/${email}/${getCurrentPassword}`)
          .then(async (res) => {
            console.log("kfnkj", res.data.length)
            if (res.data.length == 0) {
              showToast('Epilepsi Eğitim', "Güncel Şifreniz Yanlış!", 'false')
            } else {

              const updateData = {
                Users_Password: getNewPassword
              }

              await axios.patch(`https://getjob.stechomeyazilim.info:5101/patchUserEP/${userID}`, updateData).then(async (res) => {

                showToast('Epilepsi Eğitim', "Şifreniz Başarıyla Değiştirildi!", 'success')

              })


            }
          })
      } else {
        showToast('Epilepsi Eğitim', "Şifreniz Eşleşmiyor!", 'false')

      }

    } catch (error) {
      console.log("getList" + error)
    }
  }



  const [email, setEmail] = useState('');


  return (
    <Modal
      open={props._getProfileVisible}
      onClose={props._closeProfileModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className='' sx={style}>

        {getopenChangePasswordModalVisible == true ? <div className=" flex justify-center items-center   z-40">
          <div className={"flex flex-col  justify-center items-center bg-white shadow-2xl    rounded-lg w-[470px] px-2 pt-2`"}>

            <div>
              <div className="flex justify-end z-50 mr-[-40px] ">
                <button
                  onClick={() => setopenChangePasswordModalVisible(false)}
                  className="bg-[#d03730] rounded-full p-2"
                >
                  <GrClose className="" />
                </button>
              </div>


              <div className="flex w-full  shadow-xl  mb-4">
                <div
                  className="w-full flex py-3 bg-zinc-200  justify-center items-center   "

                >
                  <FaKey className="ml-3 mr-7 flex justify-center items-center w-6 h-6 fill-[#d03730]" />
                  <input
                    type={passwordShown ? "text" : "password"}

                    className="bg-zinc-200 w-full font-[Gotham-Medium] outline-none focus:border-b-2 focus:border-[#081F43] "
                    placeholder="Güncel Şifreniz"
                    onChange={e => setChangeConfirm(e.target.value, "currentPassword")}
                  />
                  <div className=" px-3 flex ">
                    <button onClick={() => setPasswordShown(!passwordShown)} className="rounded-full font-[Gotham-Medium] hover:bg-white mr-2 justify-center items-center p-1 ">
                      <AiFillEyeInvisible className="w-6 h-6 text-[#081F43]" />
                    </button>
                  </div>
                </div>
              </div>

              <div className="flex w-full  shadow-xl  mb-4">
                <div
                  className="w-full flex py-3 bg-zinc-200  justify-center items-center   "

                >
                  <FaKey className="ml-3 mr-7 flex justify-center items-center w-6 h-6 fill-[#d03730]" />
                  <input
                    type={passwordShown ? "text" : "password"}

                    className="bg-zinc-200 w-full font-[Gotham-Medium] outline-none focus:border-b-2 focus:border-[#081F43] "
                    placeholder="Yeni Şifreniz"
                    onChange={e => setChangeConfirm(e.target.value, "newPassword")}
                    value={getNewPassword}

                  />
                  <div className=" px-3 flex ">
                    <button onClick={() => setPasswordShown(!passwordShown)} className="rounded-full font-[Gotham-Medium] hover:bg-white mr-2 justify-center items-center p-1 ">
                      <AiFillEyeInvisible className="w-6 h-6 text-[#081F43]" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex w-full  shadow-xl  mb-4">
                <div
                  className="w-full flex py-3 bg-zinc-200  justify-center items-center   "

                >
                  <FaKey className="ml-3 mr-7 flex justify-center items-center w-6 h-6 fill-[#d03730] " />
                  <input
                    type={passwordShownConfirm ? "text" : "password"}
                    onChange={e => setChangeConfirm(e.target.value, "newPasswordConfirm")}
                    className="bg-zinc-200 w-full font-[Gotham-Medium] outline-none focus:border-b-2 focus:border-[#d03730] "
                    placeholder="Yeni Tekrar Şifre"
                    value={passwordConfirm}
                  />
                  <div className=" px-3 flex ">
                    <button onClick={() => setPasswordShownConfirm(!passwordShownConfirm)} className="rounded-full hover:bg-white mr-2 justify-center items-center p-1 ">
                      <AiFillEyeInvisible className="w-6 h-6 text-[#081F43]" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="w-full flex mb-4">
                <button
                  onClick={_changePasswordHandle}
                  className="mx-auto border bg-[#eb5d5f] hover:[#081F43] hover:bg-[#d03730] text-[white] px-32 py-4 rounded-lg shadow-md "
                >
                  <h2 className="font-[Gotham-Medium]">Şifre Değiştir</h2>
                </button>
              </div>


            </div>


          </div>

        </div>
          :
          <div className="w-full ">

            <div
              className={`${forgotPassword
                ? "hidden"
                : "flex flex-col  justify-center items-center"
                } bg-white shadow-2xl    rounded-lg w-[470px] px-2  `}
            >

              <div className="flex justify-end z-50 absolute -right-2 -top-5">
                <button
                  className="bg-[#d03730] rounded-full p-2"
                  onClick={props.handleLogIn}
                >
                  <GrClose className="" />
                </button>
              </div>

              {!forgotPassword && (
                <div className="w-full justify-center items-center">

                  <div className="w-full flex p-2 rounded-b-md bg-[#eb5d5f]  justify-center items-center">
                    <h2 className="text-center font-[Gotham-Medium] text-[white] ">Profilim</h2>
                  </div>


                  <div className=" w-full  mb-3">

                    <div className="w-full flex border-b-2 shadow-md">
                      <button className=" mx-auto justify-center items-center  p-2 px-32 py-4 rounded-lg flex">
                        <div className="rounded-full">
                          <FontAwesomeIcon icon={faLock} className="p-2" color={"#FFED00"} />
                        </div>
                        <text className=" font-[Gotham-Medium] text-[#d03730] ml-2" onClick={() => setopenChangePasswordModalVisible(true)}>Şifre Değiştir</text>
                      </button>
                    </div>




                    <div className="bg-[white] w-full flex shadow-md">
                      <Link to={"/favoriteadvert"} className=" mx-auto flex  justify-center items-center  p-2 px-32 py-4 rounded-lg ">
                        <div className=" rounded-full">
                          <FontAwesomeIcon icon={faHeart} className="p-2" color={"#d03730"} />
                        </div>

                        <text className=" font-[Gotham-Medium] text-[#d03730]">Favori İlanlarım</text>
                      </Link>
                    </div>

                  </div>



                </div>
              )}
            </div>


          </div>}

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Box>


    </Modal>
  );
};

export default Autharizatiom;
