import React from "react";
import downloadAppStore from "../../../assets/MainPage/GetTheApp/downloadAppStore.svg";
import getInGooglePlay from "../../../assets/MainPage/GetTheApp/getInGooglePlay.svg";
import mobileImg from "../../../assets/GetTheApp/kelepirmobil.png";

const GetTheApp = () => {
  return (
    <div className="w-full lg:h-[520px] h-full flex justify-center items-center bg-[#EBEBEB] py-10 mb-0">
      <div className="flex lg:flex-row w-4/6 h-full justify-center items-center py-5 flex-col">
        <div className="w-full h-full flex  items-center py-4">
          <div className="w-full h-full flex justify-center flex-col px-3">
            <h1 className=" font-[Lato-Medium] text-4xl mb-4" >Uygulamamızı İndirin</h1>
            <h3 className="text-xl mb-6">
              
            </h3>
            <div className="w-full grid grid-cols-2 ">
              <div className="pr-8 py-4">
                <a href="https://apps.apple.com/us/app/kelepir-i-lanlar/id6448950697" >
                  <img src={downloadAppStore} alt="" className="mr-3" />
                </a>
              </div>
              <div className="p-4">
                <a href="https://play.google.com/store/apps/details?id=com.letgokonyamobile">
                  <img src={getInGooglePlay} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-full flex justify-center items-center">
          <div className="w-200 h-[250px] lg:h-full flex justify-center items-center">
            <img src={mobileImg} alt="" className="w-full  h-full " />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetTheApp;
