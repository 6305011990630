
import React, { Component } from "react";
import axios from 'axios';
import Navbar from "../Navbar2";
import { Link } from 'react-router-dom';
import { Breadcrumbs } from "@material-tailwind/react";
import { ToastContainer, toast } from 'react-toastify';
import Moment from 'moment';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

import LoadingSpinner from "../../LoadingSpinner";
import RowAction from "./RowAction";

class EpilepsiAnket extends Component {
  constructor() {
    super();
    this.state = {
      name: "React",
      getPoolData: [],
      isLoading: true,
      getPoolQuestion: [],
      selectPoolAnswerUser: [],
      getQuestionData: [],
      open: 1
    };
    this.onValueChange = this.onValueChange.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
  }


  async componentDidMount() {
    await this._getCategory()
    await this._getSelectedQuesiton()

  }


  showToast = (event, value, type) => {
    if (type == "false") {
      toast.error(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

    } else {
      toast.success(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

    }

  }

  _getSelectedQuesiton = async () => {
    try {
      let userID = JSON.parse(localStorage.getItem("dataLogin")).ID

      axios.get(`https://getjob.stechomeyazilim.info:5101/getUsersSelectedPoolQuestion/select/${userID}`)
        .then(res => {
          console.log("sjlndfljksdf", res.data.value)
          this.setState({ isLoading: false, getQuestionData: res.data.value, selectPoolAnswerUser: res.data.value })
        })
        .catch(err => console.log("err111", err));

    }
    catch (error) {
      console.log("errorccAS" + error)
    }


  }



  selectPool = (answerID, questionID) => {
    let userID = JSON.parse(localStorage.getItem("dataLogin")).ID

    let selectPool = {
      Users_ID: userID,
      Pool_Answer_ID: answerID,
      Pool_Question_ID: questionID
    }

    let filterData = this.state.selectPoolAnswerUser.filter((x) => x.Pool_Answer_ID == answerID && x.Pool_Question_ID == questionID)

    if (filterData.length == 0) {
      this.setState(prevState => ({
        selectPoolAnswerUser: [...prevState.selectPoolAnswerUser, selectPool]
      }), () => {

      })
    } else {
      let newMarkers = this.state.selectPoolAnswerUser.map(x => (
        x.Pool_Answer_ID == answerID && x.Pool_Question_ID == questionID ? {
          ...x,
          Users_ID: userID,
          Pool_Answer_ID: answerID,
          Pool_Question_ID: questionID
        } : x
      ))

      this.setState({ selectPoolAnswerUser: newMarkers }, () => {
        console.log("newMarkers123", newMarkers)
      })
    }

  }




  _postPool = (ID) => {
    try {
      let userID = JSON.parse(localStorage.getItem("dataLogin")).ID

      var testDateUtc = Moment.utc(new Date());
      var localDate = Moment(testDateUtc).local();

      this.state.selectPoolAnswerUser.map((x) => {
        let selectPool = {
          Users_ID: userID,
          Pool_Answer_ID: x.Pool_Answer_ID,
          Pool_Question_ID: x.Pool_Question_ID
        }
        axios.post('https://getjob.stechomeyazilim.info:5101/postSelectedPoolQuestion/send', selectPool).then(async (res) => {
        })
      })


      let complatedPool = {
        Users_ID: userID,
        Pool_ID: ID,
        Users_CreatedDateTime: localDate
      }

      axios.post('https://getjob.stechomeyazilim.info:5101/postUserComplatedPool/send', complatedPool).then(async (res) => {
        this.showToast('Epilepsi Eğitim', "Anket Başarıyla Sisteme Girilmiştir!", 'success')
      })
    }
    catch (error) {
      console.log("errorccAS" + error)
    }
  }


  _getCategory = async () => {

    try {
      //   setLoginIND(JSON.parse(localStorage.getItem('dataLoginCompany')).IND)                    

      axios.get(`https://getjob.stechomeyazilim.info:5101/getPool/select/`)
        .then(res => {
          this.setState({ isLoading: false, getPoolData: res.data.value })
        })
        .catch(err => console.log("err111", err));

    }
    catch (error) {
      console.log("errorccAS" + error)
    }


  }


  onValueChange(event) {
    console.log("lsndflksd", event.target.value)
    this.setState({
      selectedOption: event.target.value
    });
  }

  formSubmit(event) {
    event.preventDefault();
    console.log(this.state.selectedOption)
  }

  handleOpen = (value) => {
    if (this.state.open) {
      this.setState({ open: 0 });

    } else {
      this.setState({ open: value });

    }
  };


  onRadioChange = (ID, questionID) => {

    let selectPool = {
      Pool_Answer_ID: ID,
      Pool_Question_ID: questionID
    }

    let filterData = this.state.selectPoolAnswerUser.filter((x) => x.Pool_Question_ID == questionID)

    if (filterData.length == 0) {
      this.setState(prevState => ({
        selectPoolAnswerUser: [...prevState.selectPoolAnswerUser, selectPool]
      }), () => {
        console.log("newMarkers1234", this.state.selectPoolAnswerUser)
      })

    } else {
      let newMarkers = this.state.selectPoolAnswerUser.map(x => (
        x.Pool_Question_ID == questionID ? {
          ...x,
          Pool_Answer_ID: ID,
          Pool_Question_ID: questionID
        } : x
      ))

      this.setState({ selectPoolAnswerUser: newMarkers }, () => {
        console.log("newMarkeccccrs123", newMarkers)
      })
    }


  }

  render() {

    return (
      <div className="w-full h-full bg-[#e9e9e9]">

        <Navbar />
        <div className="mt-36  flex flex-col h-full p-10  bg-gradient-to-r from-[#d03730] to-[#eb5d5f] items-center   ">
          <h3 className=" text-4xl w-full text-center  py-3 ">
            <span class="text-transparent bg-clip-text bg-white  font-[Gotham-Medium]">Epilepsi Anket
            </span>
          </h3>
          <div className='flex justify-center'>

            <Breadcrumbs className='bg-white'>
              <Link to="/" className="opacity-90 text-black  font-[Lato-Bold]">
                Anasayfa
              </Link>

              <Link href="/epilepsianket" className="text-black  font-[Lato-Medium]">Epilepsi Anket</Link>
            </Breadcrumbs>
          </div>
        </div>

        <div className={`${this.state.open == 1 ? "w-full justify-center items-center relative p-2 rounded-md" : "p-2 w-full justify-center items-center gap-2 grid grid-cols-2 relative rounded-md"} `}>
          {this.state.getPoolData.map((itemPool, index) => {
            return (
              <Accordion open={this.state.open === itemPool.ID}>
                <AccordionHeader className='bg-[#d03730] font-[Lato-Bold]  text-white rounded-t-md' onClick={() => this.handleOpen(itemPool.ID)}>
                  <text className="p-2 font-[Gotham-Medium] text-center text-[white]">{itemPool.Pool_Title}</text>
                </AccordionHeader>
                <AccordionBody className="rounded-b-lg  bg-[#eb5d5f] font-[Lato-Medium] p-5  text-white">
                  {itemPool.Pool_Question.map((itemQuestion, index) => {
                    return (
                      <div className="w-full ">
                        <div className="bg-[white] rounded-md justify-center items-center w-full flex">
                          <text className="font-[Lato-Medium] text-center text-[gray] p-2 text-[18px]">{itemQuestion.Pool_Question_Title}</text>
                        </div>
                        {itemQuestion.Pool_Answer.map((item, index) => {
                          let filterData = this.state.selectPoolAnswerUser.filter((x) => x.Pool_Answer_ID == item.ID)
                          return (
                            <div className="w-full justify-center items-center" >
                              <div className="w-full justify-center p-2 switch-field">
                                <input
                                  type="radio"
                                  id={`attending${(item.ID)}`}
                                  value={item.ID}
                                  checked={filterData.length > 0 ? true : false}
                                  onChange={() => this.onRadioChange(item.ID, item.Pool_Question_ID)}
                                />

                                <label
                                  htmlFor={filterData.length > 0 ? null : `attending${item.ID}`}
                                  className={filterData.length > 0 ? "attending font-[Lato-Bold]" : "font-[Lato-Medium]"}
                                >
                                  {item.Pool_Answer_Text}
                                </label>


                              </div>

                            </div>)
                        })}
                      </div>
                    )
                  })}

                  <div className={index % 2 == 0 ? "bg-[#d03730] flex w-full justify-center items-center rounded-b-md" : "bg-[#eb5d5f] flex w-full justify-center items-center rounded-b-md"}>
                    <button onClick={() => this._postPool(itemPool.ID)} className=" justify-center items-center w-full p-3 flex">
                      <text className="font-[Lato-Medium] text-center text-white">Formu Tamamla</text>
                    </button>
                  </div>


                </AccordionBody>
              </Accordion>
            )
          })}

        </div>



        <LoadingSpinner show={this.state.isLoading} />

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />

      </div>
    )
  }
}

export default EpilepsiAnket;
