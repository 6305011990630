import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import Navbar2 from "../Navbar2";

import AdvertMain from "./AdvertMain/AdvertMain";
import AdvertCategory from "./AdvertCategory/AdvertCategory";
import GetTheApp from "./GetTheApp/GetTheApp";
import Slider from "./Slider/Slider";
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import LoadingSpinner from "../../LoadingSpinner";
import { observer, inject } from 'mobx-react'

const MainPage = inject()(observer(() => {
  const [isLoading, setIsLoading] = React.useState(true);


  return (
    <div className="w-full h-full">
      {isLoading == false ? <Navbar2 /> : null}
      <div className="w-full h-screen pt-[145px]">
        <div className="flex w-full justify-center items-center h-5/6">
          <div className="h-full z-0 w-full">
            <Slider setIsLoading={setIsLoading} />
          </div>
        </div>

       
        <AdvertCategory />

        <AdvertMain />
        <GetTheApp />
        <Footer />
        <LoadingSpinner show={isLoading} />
      </div>
    </div>
  );
}));

export default MainPage;
