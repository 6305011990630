import React, { useEffect } from "react";

import { useState } from "react";


import { observer, inject } from 'mobx-react'


import twtlogo from '../assets/twit.png';
import fcblogo from '../assets/face.png';
import instalogo from '../assets/insta.png';
import linkedinlogo from '../assets/link.png';
import ytblogo from '../assets/youtube.png';
import tiktoklogo from '../assets/tiktok.png';


const Navbar = inject()(observer((props) => {

    const [nav, setNav] = useState(true);


    const [product, setProduct] = useState(false);
    const [deliverables, setDeliverables] = useState(false);

    const [checkDisplayMenu, setDisplayMenu] = useState(false);


    const hideDropdownMenu = () => {
        setDisplayMenu(!checkDisplayMenu)
        document.querySelector('#dropdown').removeEventListener('click', hideDropdownMenu);

    }

    return (

        <nav className="w-full fixed  bg-[#FFED00] shadow  z-20 ">
            <div className="justify-end container h-8 flex items-stretch ">


                <div className="flex items-center">
                    <div className="flex flex-row justify-between">
                        <a href="/"><img src={twtlogo} alt="" className="mx-2 w-5 h-5 " /></a>
                        <a href="/"><img src={fcblogo} alt="" className="mx-2 w-5 h-5" /></a>
                        <a href="/"><img src={instalogo} alt="" className="mx-2 w-5 h-5" /></a>
                        <a href="/"><img src={linkedinlogo} alt="" className="mx-2 w-5 h-5" /></a>
                        <a href="/"><img src={ytblogo} alt="" className="mx-2 w-5 h-5" /></a>
                        <a href="/"><img src={tiktoklogo} alt="" className="mx-2 w-5 h-5" /></a>
                    </div>

                    <ul className="pr-32 xl:flex hidden items-center h-full">
                        <li className="hover:text-[#896de] cursor-pointer h-full flex items-center text-sm text-indigo-700 tracking-normal">Dashboard</li>
                        <li className="hover:text-[#896de] cursor-pointer h-full flex items-center text-sm text-gry-800 mx-10 tracking-normal relative">
                            {product ? (
                                <ul className="bg-white shadow rounded py-1 w-32 left-0 mt-16 -ml-4 absolute  top-0">
                                    <li className="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 px-3 font-normal">Landing Pages</li>
                                    <li className="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 px-3 font-normal">Templates</li>
                                    <li className="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 px-3 font-normal">Components</li>
                                </ul>
                            ) : (
                                ""
                            )}
                            Products
                            <span className="ml-2" onClick={() => setProduct(!product)}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down" width={16} height={16} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" />
                                    <polyline points="6 9 12 15 18 9" />
                                </svg>
                            </span>
                        </li>
                        <li className="hover:text-indigo-700 cursor-pointer h-full flex items-center text-sm text-gry-800 mr-10 tracking-normal">Performance</li>
                        <li className="hover:text-indigo-700 cursor-pointer h-full flex items-center text-sm text-gray-800 tracking-normal relative">
                            {deliverables ? (
                                <ul className="bg-white shadow rounded py-1 w-32 left-0 mt-16 -ml-4 absolute  top-0">
                                    <li className="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 px-3 font-normal">ürünler Pages</li>
                                    <li className="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 px-3 font-normal">Templates</li>
                                    <li className="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 px-3 font-normal">Components</li>
                                </ul>
                            ) : (
                                ""
                            )}
                            Deliverables
                            <span className="ml-2" onClick={() => setDeliverables(!deliverables)}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down" width={16} height={16} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" />
                                    <polyline points="6 9 12 15 18 9" />
                                </svg>
                            </span>
                        </li>
                    </ul>
                </div>


            </div>
        </nav>
    );
}));

export default Navbar;

