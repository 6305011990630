import React, { useState, useEffect } from "react";
import Navbar from "../Navbar2";
import { Link } from 'react-router-dom';

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";

import { useLocation } from "react-router-dom";
import moment from "moment";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

import { EyeIcon, HeartIcon } from "@heroicons/react/24/solid";

const BlogDetail = (props) => {
    const location = useLocation();
    const state = location.state;
    const today = moment().format('YYYY-MM-DD');
    const [isLoading, setIsLoading] = useState(false);
    const [getData, setData] = useState([]);
    const [getCategoryData, setCategoryData] = useState([]);
    const [getEducationCategory, setEducationCategory] = useState([]);

    useEffect(() => {
        setIsLoading(true)
        const getCategory = async () => {

            try {
                //   setLoginIND(JSON.parse(localStorage.getItem('dataLoginCompany')).IND)                    


                axios.get(`https://getjob.stechomeyazilim.info:5101/getAdvertCategoryLG/select/`)
                    .then(res => {
                        console.log("1231ccc23", res.data.value)
                        setEducationCategory(res.data.value);



                    })
                    .catch(err => console.log("err111", err));

            }
            catch (error) {
                console.log("errorccAS" + error)
            }


        }


        getCategory()
    }, []);

    useEffect(() => {

        axios.get(`https://getjob.stechomeyazilim.info:5101/getUserAdvertLP/select/${state.getData[0].Users.ID}`)
            .then(res => {
                setData(res.data.value);
                props.setIsLoading(false)
            })
            .catch(err => console.log(err));
    }, []);
    console.log(state.getData, 'ooh');


    return (
        <div>
            <Navbar />
            <div className="w-full flex flex-col">
                <div className="max-w-full mt-36 flex flex-col  h-full  items-center  ">
                <div className="bg-gradient-to-r from-[#d03730] to-[#eb5d5f]  flex justify-center items-center p-2 w-1/2 rounded-lg">
                                    <text className="text-xl p-2 font-[Lato-Medium]  text-white">{state.getData[0].Users.Users_Name_Surname} İlanlar</text>
                                </div>
                    <div className="w-1/1.5 h-full p-2 items-center  grid xxs:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 gap-2 m-4">
              

                        {state.getData.map((item, index) => {
                            return (


                                <div className="items-center m-4 border border-solid border-3px border-[#ebeaea] bg-[#ebeaea] rounded-t-lg ">



                                    <div className="w-full bg-[#ebeaea] flex-col h-98 flex justify-center items-center rounded-t-lg">
                                        <text className="font-[Lato-Medium] text-center shadow-xl ">Yayınlanma tarihi: {moment(item.Advert_Date).format('YYYY-MM-DD')}</text>
                                        <img
                                            src={'https://letgoadmin.stechomeyazilim.info/' + item.Advert_MainImage}
                                            alt=""
                                            className="w-full h-80 rounded-t-lg"
                                        />
                                    </div>



                                    <div className="bg-[#F7F7F7] flex-col flex relative w-full justify-center items-center rounded-b-md text-center " >
                                        <text className="font-[Lato-Medium] text-center p-5 "> {item.Advert_Title}</text>
                                        <text className="font-[Lato-Medium] text-center text-[#d03730]"> {item.Advert_Price} ₺</text>


                                        <div className="w-full flex-row justify-evenly flex m-2">

                                            <div className="flex-row flex">
                                                <HeartIcon strokeWidth={1} className="w-6 text-[#7e7d7d]" />
                                                <text className="font-[Lato-Medium] text-center shadow-xl text-[#7e7d7d]">Beğeniler:  {item.FavouriteAdvert.length}</text>
                                            </div>
                                            <div className="flex-row flex">
                                                <EyeIcon strokeWidth={1} className="w-6 text-[#7e7d7d] " />
                                                <text className="font-[Lato-Medium] text-center shadow-xl text-[#7e7d7d]">Erişim: {item.Advert_Seen}</text>
                                            </div>
                                        </div>
                                        <Link to={"/advertdetail/" + item.Advert_Title.replace('?', '').replace(/\s+/g, '-')} state={{ allData: getCategoryData, _item: item }} className="w-full text-center justify-center items-center bg-[#d03730]  p-3">
                                            <text className="font-[Lato-Medium] text-center text-[white] ">İNCELE</text>
                                        </Link>
                                    </div>


                                </div>
                            )
                        })}





                    </div>
                </div>
            </div>


            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
};

export default BlogDetail;
