
import React, { Fragment, useEffect, useState } from 'react'
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import Navbar from "../Navbar2";
import { Breadcrumbs } from "@material-tailwind/react";
import Footer from "../Footer";
import axios from 'axios';
import { Link } from 'react-router-dom';
import moment from "moment";
import { EyeIcon, HeartIcon } from "@heroicons/react/24/solid";
export default function Example() {
    const [open, setOpen] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [getCategoryData, setCategoryData] = useState([]);
    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };
    const [isFavourite, setIsFavourite] = useState(false);

    const handleFavouriteClick = () => {
        setIsFavourite(!isFavourite);
    };
    const [sss, setAuthors] = useState([]);
    useEffect(() => {

        let userID = JSON.parse(localStorage.getItem("dataLogin")).ID

        axios.get(`https://getjob.stechomeyazilim.info:5101/getFavoriteAdvertLG/select/${userID}`)
            .then(res => {
                setAuthors(res.data.value);
            })
            .catch(err => console.log(err));
    }, []);
    console.log('dada', sss)

    return (


        <div className='w-full h-full justify-between'>
            <Navbar />
            <div className="w-full   flex flex-col justify-center">
                <div className="  max-w-full mt-36  flex flex-col h-full p-10 bg-gradient-to-r from-[#d03730] to-[#eb5d5f] items-center   ">
                    <h3 className=" text-4xl w-full text-center  py-3 font-[Gotham-Medium]">
                        <span class="text-transparent bg-clip-text bg-white font-[Gotham-Medium]">FAVORİ İLANLAR
                        </span>
                    </h3>
                    <div className='flex justify-center'>

                        <Breadcrumbs className='bg-white'>
                            <Link to="/" className="opacity-90 text-black font-[Lato-Bold]">
                                Anasayfa
                            </Link>

                            <Link to="/sss" className="opacity-90 text-black font-[Lato-Medium]">Favori İlanlar</Link>
                        </Breadcrumbs>
                    </div>
                </div>

                <div className='self-center justify-center items-center  w-3/4' >


                    <Fragment>
                        <div className="w-1/1.5 h-full p-2 items-center  grid xxs:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 gap-2 m-4">


                            {sss.map(item => (

                                <div className="relative items-center m-4 border border-solid border-3px border-[#ebeaea] bg-[#ebeaea] rounded-t-lg ">


                                    <button
                                        className={`absolute right-0 top-0  rounded-full bg-[#c8c8c8]  border-2  p-1 flex items-center gap-1 text-gray ' 
                                            `}
                                        onClick={handleFavouriteClick}
                                    >  <HeartIcon strokeWidth={1} className="w-6  text-red-500" />


                                    </button>
                                    <div className="w-full bg-[#ebeaea] flex-col h-98 flex justify-center items-center rounded-t-lg">
                                        <text className="font-[Lato-Medium] text-center shadow-xl ">Yayınlanma tarihi: {moment(item.Advert.Advert_Date).format('YYYY-MM-DD')}</text>
                                        <img
                                            src={'https://letgoadmin.stechomeyazilim.info/' + item.Advert.Advert_MainImage}
                                            alt=""
                                            className="w-full h-80 rounded-t-lg"
                                        />
                                    </div>



                                    <div className="bg-[#F7F7F7] flex-col flex relative w-full justify-center items-center rounded-b-md text-center " >
                                        <text className="font-[Lato-Medium] text-center p-5 "> {item.Advert.Advert_Title}</text>
                                        <text className="font-[Lato-Medium] text-center text-[#d03730]"> {item.Advert.Advert_Price} ₺</text>


                                        <div className="w-full flex-row justify-evenly flex m-2">

                                            <div className="flex-row flex">
                                                <HeartIcon strokeWidth={1} className="w-6 text-[#7e7d7d]" />
                                                <text className="font-[Lato-Medium] text-center shadow-xl text-[#7e7d7d]">Beğeniler:{item.Advert.FavouriteAdvert.length}  </text>
                                            </div>
                                            <div className="flex-row flex">
                                                <EyeIcon strokeWidth={1} className="w-6 text-[#7e7d7d] " />
                                                <text className="font-[Lato-Medium] text-center shadow-xl text-[#7e7d7d]">Erişim: {item.Advert.Advert_Seen}</text>
                                            </div>
                                        </div>
                                        <Link to={"/advertdetail/" + item.Advert.Advert_Title.replace('?', '').replace(/\s+/g, '-')} state={{ allData: getCategoryData, _item: item }} className="w-full text-center justify-center items-center bg-[#d03730]  p-3">
                                            <text className="font-[Lato-Medium] text-center text-[white] ">İNCELE</text>
                                        </Link>
                                    </div>


                                </div>
                            ))}
                        </div>
                    </Fragment>
                </div>

            </div>


            <Footer />

        </div>
    );
}