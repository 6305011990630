import React, { useEffect, useState } from "react";
import twtlogo from '../assets/tiwtternocolor.svg';
import fcblogo from '../assets/facebooknocolor.svg';
import instalogo from '../assets/instanolcolor.svg';
import linkedinlogo from '../assets/linkedinnocolor.svg';
import ytblogo from '../assets/ytbnocolor.svg';
import tiktoklogo from '../assets/tiktok.svg';
import BeyazLogo from '../KelepirBeyazz.png';
import axios from 'axios';
import { Link } from "react-router-dom";
import { FaAppStore, FaGooglePlay } from 'react-icons/fa';


const Footer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [getAdvertCategory, setEducationCategory] = useState([]);
  useEffect(() => {
    setIsLoading(true)
    const getCategory = async () => {

      try {
        //   setLoginIND(JSON.parse(localStorage.getItem('dataLoginCompany')).IND)                    


        axios.get(`https://getjob.stechomeyazilim.info:5101/getAdvertCategoryLG/select/`)
          .then(res => {
            console.log("1231ccc23", res.data.value)
            setEducationCategory(res.data.value);

          })
          .catch(err => console.log("err111", err));

      }
      catch (error) {
        console.log("errorccAS" + error)
      }


    }


    getCategory()
  }, []);
  const [about, setAuthors] = useState([]);
  useEffect(() => {

    axios.get('https://getjob.stechomeyazilim.info:5101/getAboutLG/select/')
      .then(res => {
        setAuthors(res.data.value[0]);
      })
      .catch(err => console.log(err));
  }, []);
  return (
    <div className="w-full justify-center items-center  bg-gradient-to-r  from-[#d03730] to-[#eb5d5f]  flex flex-col text-white z-20">
      <div className="w-full h-full justify-center items-center  flex p-2 ">
        <div className="w-full h-full flex justify-between md:pl-16 md:pr-24 pr-3">

          <div className="flex justify-center items-center text-left md:mr-0 mr-2 ">
            <img src={BeyazLogo} alt="" className="mx-2 w-56" />
          </div>
          <div className="flex flex-col text-left md:mr-0 mr-2">
            <h3 className="text-white font-[Lato-Bold]  mb-3 border-b-2 text-center ">KURUMSAL</h3>
            <a href="/" className="text-xs mb-1 font-[Lato-Medium] text-center">
              ANASAYFA
            </a>
            <Link to="/hakkimizda" className="text-xs mb-1 font-[Lato-Medium]   text-center">
              HAKKIMIZDA
            </Link>
            <Link to="/iletisim" className="text-xs mb-1 font-[Lato-Medium]  text-center">
              İLETİŞİM
            </Link>
            <Link to="/sss" className="text-xs mb-1 font-[Lato-Medium] text-center">
              SSS
            </Link>
          </div>

          <div className="flex flex-col items-center text-left md:mr-0 mr-2">
            <h3 className="text-white font-[Lato-Bold]  mb-3 border-b-2 text-center ">İLANLAR</h3>

            {getAdvertCategory.map((item) => {
              return (
                <Link to={"/advertsubcategory/" + item.ID} className="text-xs font-[Lato-Medium]" state={{ _item: item }} >
                  {item.Advert_Category_Title}

                </Link>)
            })}


          </div>


          <div className="flex flex-col text-left ml-2">
            <h3 className="text-white  mb-3 font-[Lato-Bold] border-b-2 text-center ">İLETİŞİM</h3>
            <a href="/" className="text-xs mb-1 font-[Lato-Medium] text-center">
              Adres: {about.Company_Adress}
            </a>
            <a href="/" className="text-xs mb-1 font-[Lato-Medium] text-center">
              Telefon: {about.Company_TelephoneNumber}
            </a>

            <a href="/" className="text-xs mb-1 font-[Lato-Medium] text-center">
              Faks: {about.Company_FaxNumber}
            </a>
            <a href="/" className="text-xs mb-1 font-[Lato-Medium] text-center">
              Eposta: {about.Company_MailAdress}

            </a>
          </div>
          <div className="flex flex-col text-left ml-2 justify-center items-center">
          <h3 className="text-white  mb-3 font-[Lato-Bold] border-b-2 text-center ">MOBİL UYGULAMAMIZ</h3>
         
            <a className="flex flex-row items-center" href="https://apps.apple.com/us/app/kelepir-i-lanlar/id6448950697"><FaAppStore className="mr-2"/>   App Store</a>
            <a  className="flex flex-row items-center"href="https://play.google.com/store/apps/details?id=com.letgokonyamobile"><FaGooglePlay className="mr-2"/> Google Play</a>

          </div>

        </div>
      </div>
      <div className="w-full  p-2 border border-t-2 border-t-[white] border-x-0  ">
        <div className="w-full flex justify-between items-center">
          <div >
            <p className="text-white text-sm font-[Lato-Medium]">© 2023 by Stechome Software</p>
          </div>
          <div className="flex flex-row justify-between">
            <a href="/"><img src={twtlogo} alt="" className="mx-2 w-5 h-5" /></a>
            <a href="/"><img src={fcblogo} alt="" className="mx-2 w-5 h-5" /></a>
            <a href="/"><img src={instalogo} alt="" className="mx-2 w-5 h-5" /></a>
            <a href="/"><img src={linkedinlogo} alt="" className="mx-2 w-5 h-5" /></a>
            <a href="/"><img src={ytblogo} alt="" className="mx-2 w-5 h-5" /></a>
            <a href="/"><img src={tiktoklogo} alt="" className="mx-2 w-5 h-5" /></a>
          </div>
        </div>
      </div>




    </div>
  );
};

export default Footer;
