import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { FreeMode, Pagination, Navigation } from "swiper";
import "swiper/css/free-mode";
import "swiper/css";
import "swiper/css/pagination";
import SliderCard from "./SliderCard";
import axios from 'axios';

import { Link } from "react-router-dom";


const Announcement = () => {
  const [advertData, setAuthors] = useState([]);

  
  useEffect(() => {

    axios.get('https://getjob.stechomeyazilim.info:5101/getPremiumAdvertLG/select/1')
      .then(res => {
        setAuthors(res.data.value);
      })
      .catch(err => console.log(err));
  }, []);



  
  return (
    <div className="w-full mt-5  flex flex-col justify-center items-center mb-0">

      <div className="w-full pt-2   justify-center items-center pb-3"> 

        <h3 className="flex h-full items-center text-secondcolor justify-center">
          <span class="text-transparent bg-clip-text bg-gradient-to-tl to-[#d03730] from-[#eb5d5f] font-[Lato-Medium] text-3xl">GÜNCEL İLANLAR</span>
        </h3>

        <Link className=" mr-10 float-right " to={"/advert"} >
            Tümünü Gör
        </Link>
      </div>

      <div className="w-full flex p-2">
        <Swiper
          freeMode={true}
          grabCursor={true}
          modules={[FreeMode, Pagination, Navigation]} 
          className="mySwiper"
          pagination={{
            clickable: true,
          }}
          style={{
            "--swiper-pagination-color": "#eb5d5f",
            "--swiper-pagination-bullet-size": "8px",
            "--swiper-pagination-bullet-width": "20px",
            "--swiper-pagination-bullet-height": "20px",
            "--swiper-pagination-bullet-inactive-color": "gray",
            "--swiper-pagination-bullet-inactive-opacity": "0.8",
            "--swiper-pagination-bullet-opacity": "1",
            "--swiper-pagination-bullet-horizontal-gap": "5px",
            "--swiper-pagination-bullet-vertical-gap": "5px",
            "--swiper-navigation-color": "white",

            "--swiper-navigation-size": "25px",
          }}
          navigation={true}
          loop={true}
          loopFillGroupWithBlank={true}
          breakpoints={{
            480: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            780: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 15,
            },
            1280: {
              slidesPerView: 4,
              spaceBetween: 15,
            },

          }}
        >
          {advertData.slice(-5).map(item => (
            <SwiperSlide>


              <SliderCard _item={item} />

            </SwiperSlide>
          ))}

        </Swiper>
      </div>




    </div>


  );
};

export default Announcement;
