import React, { Fragment, Component } from 'react';
import { Alert } from 'react-bootstrap';
import axios from 'axios';
import Navbar from "../Navbar2";
import { ToastContainer, toast } from 'react-toastify';
import { Breadcrumbs } from "@material-tailwind/react";
import { Link } from 'react-router-dom';

import Footer from "../Footer";
class Content extends Component {
  constructor() {
    super();
    this.state = {
      contactDetail: {}
    }
  }

  recaptchaRef = React.createRef(null);


  async componentDidMount() {
    await this._getContact()
  }

  _getContact = async () => {
    try {

      await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getContact/select/`)
        .then(res => {
          this.setState({ contactDetail: res.data[0] })
        })
    }
    catch (error) {

      console.log("_getContact" + error)
    }
  }


  _handleSubmit = async (e) => {
    e.preventDefault();

    const captchaToken = await this.recaptchaRef.current.executeAsync();
    this.recaptchaRef.current.reset();

    console.log("captchaToken123", captchaToken)
    const res = await axios.post(
      `https://www.google.com/recaptcha/api/siteverify?secret=${"6LcyxIYkAAAAABj3DAD7U33_m6Ui1QfLvNF7B-o2"}&response=${captchaToken}`
    );

    console.log("captchaT12oken123", res.data)

    // Extract result from the API response
    if (res.data.success) {
      console.log('Human');
    }

    else {
      console.log('BOT!!!');

    }

  }

  showToast = (event, value, type) => {
    if (type == "false") {
      toast.error(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

    } else {
      toast.success(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      window.location.reload(false);

    }

  }

  _postMessage = () => {
    this.showToast('Epilepsi Eğitimi', "Mesajınız başarıyla tarafımıza iletildi!", 'false')

  }

  render() {
    const FORM_ENDPOINT = ""; // TODO - fill on the later step

    return (
      <div className="">
        <Navbar />
        <div className="  max-w-full mt-36  flex flex-col h-full p-10  bg-gradient-to-r from-[#d03730] via-[#eb5d5f] to-[#FFED00] items-center   ">
          <h3 className=" text-4xl w-full text-center  py-3 ">
            <span class="text-transparent bg-clip-text bg-[white] font-[Gotham-Medium]">İletişim
            </span>
          </h3>
          <div className='flex justify-center'>

            <Breadcrumbs className='bg-white'>
              <Link to="/" className="opacity-90 text-black font-[Gotham-Medium]">
                Anasayfa
              </Link>

              <Link href="/hakkimizda" className="text-black font-[Gotham-Medium]" >Ekibimiz</Link>
            </Breadcrumbs>
          </div>
        </div>

        <div className="w-full">
          <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3147.5287839317607!2d32.5545263153334!3d37.91807547973371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d08fde0028003f%3A0x9a34903fadff627e!2sBuhara%20Baharat!5e0!3m2!1str!2str!4v1670849603678!5m2!1str!2str" className="border-0 w-screen	" allowFullScreen />
        </div>


        <div className="mx-auto border-2 border-[#d03730] items-center justify-center rounded-lg w-1/2 m-10">
          <div className="bg-gradient-to-r from-[#d03730] to-[#eb5d5f] justify-center items-center w-full">
            <h6 className="text-light-green text-[30px] text-center  font-[Gotham-Medium] text-white">İletişim Formu</h6>
            <h3 className="text-light-green text-[10px] text-center  font-[Gotham-Medium] text-white">KELEPİR İLANLAR</h3>
          </div>

          <form
            action={FORM_ENDPOINT}
            onSubmit={this._postMessage}
            method="POST"
            target="_blank"
          >
            <div className="m-5">
              <input
                type="text"
                placeholder="Adınız"
                name="name"
                className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white  rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
                required
              />
            </div>
            <div className="m-5">
              <input
                type="email"
                placeholder="Mail Adresiniz"
                name="email"
                className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
                required
              />
            </div>
            <div className="m-5">
              <textarea
                placeholder="Mesajınız"
                name="message"
                className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
                required
              />
            </div>
            <div className="m-5 justify-center items-center text-center">
              <button onClick={() => this._postMessage()}
                className="bg-[#eb5d5f] text-white active:bg-[#d03730] font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="submit"
              >
                Gönder
              </button>
            </div>
          </form>
        </div>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Footer />
      </div>
    );
  }
}

export default Content;