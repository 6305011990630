import moment from "moment";
import React from "react";

const BlogCard = (props) => {
  return (
    <div className=" flex  items-center justify-center flex-col border rounded-3xl shadow-xl">
      <div className="p-2">
      <img src={'https://epilepsiadmin.stechomeyazilim.info' + props._item.Blog_Image} alt="" className="h-52 rounded-md " />
      </div>
      <div className="w-full flex flex-col bg-[#d03730]">
        <div className="text-center">
        <h1 className="text-2xl font-[Lato-Bold] text-white">{props._item.Blog_Title}</h1>
                  <h1 className=" text-xs text-white font-[Lato-Medium]">{props._item.Blog_ShortDesc}</h1>
        </div>
        <div className="w-full flex flex-row justify-between">
          <p className="pl-2 text-white ">{moment(props._item.Blog_CreatedDateTime).format('YYYY-MM-DD')}</p>
            <button className=" bg-[#eb5d5f] flex justify-center items-center m-2 p-2 rounded-md hover:bg-[#F2B129] ">
              <p className="text-white font-[Lato-Bold]">İncele</p>
            </button>
         
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
