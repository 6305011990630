import React, { Component } from "react";
import styled, { css } from "styled-components";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Logo from "./logo2.png"
import { width } from "@mui/system";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  outline: 'none'

};

export default function (props) {
  return (

    <Modal
      open={props.show}
      onClose={() => props.setShow(false)}
    >
      <Box sx={style}>

        <div className=" justify-center items-center text-center  self-center content-center justify-items-center ">

          <div className='flex  justify-center items-center text-center'>

            <img src={Logo} alt="/" className="p-5 justify-center items-center w-1/4 bg-white rounded-lg" />
          </div>


        </div>

      </Box>
    </Modal>
  );

}


