import React, { useState, useEffect } from "react";
import Navbar from "../Navbar2";
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import { FreeMode, Pagination, Navigation } from "swiper";
import EpilepsiCard from "./EpilepsiCard";
import Footer from "../Footer";
import { useLocation } from "react-router-dom";
import moment from "moment";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import SliderCard from "../MainPage/AdvertMain/SliderCard";
import ReactHtml from 'raw-html-react';
import base64 from 'react-native-base64'
import ReactPlayer from 'react-player'

const BlogDetail = (props) => {
    const location = useLocation();
    const state = location.state;
    const today = moment().format('YYYY-MM-DD');
    const startDate = moment(state._item.Advert_StartDate).format('YYYY-MM-DD');
    const [getData, setData] = useState([]);

    const [loginData, setLoginData] = useState(null);



    useEffect(() => {



        const _getList = async () => {


            try {
                console.log("jsndfjkls", JSON.parse(localStorage.getItem('dataLogin')))
                if (JSON.parse(localStorage.getItem('dataLogin')) != null) {
                    setLoginData(JSON.parse(localStorage.getItem('dataLogin')))
                }

            }
            catch (error) {
                console.log("errorccAS" + error)
            }


        }



        _getList()
    }, []);

    useEffect(() => {

        axios.get(`https://getjob.stechomeyazilim.info:5101/getUserAdvertLP/select/${state._item.User_ID}`)
            .then(res => {
                setData(res.data.value);
                props.setIsLoading(false)
            })
            .catch(err => console.log(err));
    }, []);


    const [advertData, setAuthors] = useState([]);


    useEffect(() => {

        axios.get('https://getjob.stechomeyazilim.info:5101/getPremiumAdvertLG/select/1')
            .then(res => {
                setAuthors(res.data.value);
            })
            .catch(err => console.log(err));
    }, []);

    return (
        <div>
            <Navbar />
            <div className="w-full flex flex-col">
                <div className="max-w-full mt-36 flex flex-col bg-[#F7F7F7] h-full  items-center  ">

                    <div className="w-full h-full p-2 items-center flex flex-col">
                        <div className="border-l-8 border-[#d03730] p-3 mt-2">
                            <p className="ml-2 text-[#d03730] text-[20px]">
                                {state._item.Advert_Title}
                            </p>
                        </div>
                        <div className="w-full flex justify-center relative">
                            <div>

                            {state._item.Advert_IsLetgo==false ?
                                <img src={'https://letgoadmin.stechomeyazilim.info' + state._item.Advert_MainImage} className=" h-80 rounded-md" />
                           
                           :    <img src={ state._item.Advert_MainImage} className=" h-80 rounded-md" />
                            }
                           
                                </div>
                        </div>




                        <div className="bg-white justify-center items-center p-2 m-4 w-1/2 text-center ">
                            <div className="bg-[#d03730] justify-center items-center p-2 w-full rounded-lg">
                                <text className="text-xl p-2 font-[Lato-Medium] text-center text-white">Detaylar</text>
                            </div>


                            <div>
                                <div className="w-full flex justify-center relative">
                                    <p className="ml-2 text-[black] text-[16px]">
                                        Ücret:
                                    </p>
                                    <p className="ml-2 text-[black] text-[16px]">
                                        {state._item.Advert_Price} ₺
                                    </p>

                                </div>
                                <div className="w-full flex justify-center relative">
                                    <p className="ml-2 text-[black] text-[16px]">
                                        İlan Tarihi:
                                    </p>
                                    <p className="ml-2 text-[#b3b2b2] text-[16px]">

                                        {moment(today).diff(startDate, 'days')} gün önce
                                    </p>
                                </div>



                            </div>

                        </div>
                        {state._item.Advert_Desc != null ?
                            <div className="bg-[white]  p-2 m-4 w-1/2 text-center ">
                                <div className="bg-[#d03730] justify-center items-center p-2 w-full rounded-lg">
                                    <text className="text-xl p-2 font-[Lato-Medium]  text-white">Açıklama</text>
                                </div>

                                <p className="ml-2 text-[#494444] text-[16px]">

                                    <ReactHtml style={{ color: 'white' }} html={state._item.Advert_Desc} />
                                </p>

                            </div>
                            : null}


                        <div className="bg-white justify-center items-center p-2 m-4 w-1/2 text-center ">
                            <div className="bg-[#d03730] justify-center items-center p-2 w-full rounded-lg">
                                <text className="text-xl p-2 font-[Lato-Medium] text-center text-white">Satıcı Bilgileri</text>
                            </div>

                            {loginData != null ?

                                getData.length > 0 ?
                                    <div className="flex flex-col md:flex-row justify-center items-center">
                                      
                                      
                                        <img
                                            className="w-full md:w-1/6"
                                            src={'https://letgoadmin.stechomeyazilim.info' + getData[0].Users.Users_Profile_Photo}
                                            alt={getData[0].Users.Users_Name_Surname}
                                        />
                                        <div className="w-full md:w-1/2 flex-row" >
                                            <h1 className="text-2xl font-bold">  {getData[0].Users.Users_Name_Surname}</h1>
                                            <p className="mt-2">  {getData[0].Users.Users_MailAddress}</p>
                                            <Link to={"/advertuser/"} state={{ getData: getData }} className="text-center justify-center items-center w-full p-2">
                                                <text className="font-[Lato-Medium] text-[black] text-center shadow-xl ">Profili Görüntüle</text>
                                            </Link>
                                        </div>

                                    </div> : null


                                :
                                <div className="flex flex-col md:flex-row justify-center items-center">
                                    <Link to={"/"} state={{ getData: getData }} className="bg-[#eb5d5f] rounded-md text-center justify-center items-center w-48 m-5 p-2">
                                        <text className="font-[Lato-Medium] text-[white] text-center shadow-xl ">Kayıt Ol</text>
                                    </Link>
                                    <Link to={"/"} state={{ getData: getData }} className="text-center justify-center items-center rounded-md bg-slate-900 w-48 p-2">
                                        <text className="font-[Lato-Medium] text-[white] text-center shadow-xl ">Giriş Yap</text>
                                    </Link>
                                </div>
                            }




                        </div>

                        <div className="bg-white justify-center items-center p-2 m-4 w-1/2 text-center ">
                            <div className="bg-[#d03730] justify-center items-center p-2 w-full rounded-lg">
                                <text className="text-xl p-2 font-[Lato-Medium] text-center text-white">Harita Bilgileri</text>
                            </div>
                            {loginData != null ?
                                <div className=" justify-center items-center p-2 w-full rounded-lg">

                                    <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3147.5287839317607!2d32.5545263153334!3d37.91807547973371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d08fde0028003f%3A0x9a34903fadff627e!2sBuhara%20Baharat!5e0!3m2!1str!2str!4v1670849603678!5m2!1str!2str" className="border-0 w-full	" allowFullScreen />




                                </div>
                                :
                                <div className="flex flex-col md:flex-row justify-center items-center">
                                    <Link to={"/"} state={{ getData: getData }} className="bg-[#eb5d5f] rounded-md text-center justify-center items-center w-48 m-5 p-2">
                                        <text className="font-[Lato-Medium] text-[white] text-center shadow-xl ">Kayıt Ol</text>
                                    </Link>
                                    <Link to={"/"} state={{ getData: getData }} className="text-center justify-center items-center rounded-md bg-slate-900 w-48 p-2">
                                        <text className="font-[Lato-Medium] text-[white] text-center shadow-xl ">Giriş Yap</text>
                                    </Link>
                                </div>}

                        </div>

                        <div className="bg-white flex  items-center justify-between  p-2 m-4 w-1/2 text-center ">
                            <text className="text-xs p-2 font-[Lato-Medium]  text-center text-[#bdbbbb]">İLAN NO: {state._item.ID} </text>

                            <Link to={"/advertdetail/"} className="items-center  p-3">
                                <text className="font-[Lato-Medium] text-[black] text-xs  text-center shadow-xl ">İlanı şikayet et</text>
                            </Link>
                        </div>

                    </div>
                </div>
            </div>
            <div className="w-full pt-2  justify-center items-center pb-3">

                <h3 className="flex h-full items-center text-secondcolor justify-center">
                    <span class="text-transparent bg-clip-text bg-gradient-to-tl to-[#d03730] from-[#eb5d5f] font-[Lato-Medium] text-3xl">BENZER İLANLAR</span>
                </h3>

                <Link className=" mr-10 float-right " to={"/advert"} >
                    Tümünü Gör
                </Link>
            </div>

            <div className="w-full flex p-2">
                <Swiper
                    freeMode={true}
                    grabCursor={true}
                    modules={[FreeMode, Pagination, Navigation]}
                    className="mySwiper"
                    pagination={{
                        clickable: true,
                    }}
                    style={{

                        "--swiper-pagination-color": "red",
                        "--swiper-pagination-bullet-size": "8px",
                        "--swiper-pagination-bullet-width": "20px",
                        "--swiper-pagination-bullet-height": "20px",
                        "--swiper-pagination-bullet-inactive-color": "gray",
                        "--swiper-pagination-bullet-inactive-opacity": "0.8",
                        "--swiper-pagination-bullet-opacity": "1",
                        "--swiper-pagination-bullet-horizontal-gap": "5px",
                        "--swiper-pagination-bullet-vertical-gap": "15px",
                        "--swiper-navigation-color": "white",

                        "--swiper-navigation-size": "25px",
                    }}
                    navigation={true}
                    loop={true}
                    loopFillGroupWithBlank={true}
                    breakpoints={{
                        480: {
                            slidesPerView: 1,
                            spaceBetween: 10,
                        },
                        780: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 15,
                        },
                        1280: {
                            slidesPerView: 4,
                            spaceBetween: 15,
                        },

                    }}
                >
                    {advertData.slice(-5).map(item => (
                        <SwiperSlide>


                            <SliderCard _item={item} />

                        </SwiperSlide>
                    ))}

                </Swiper>
            </div>



            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Footer />
        </div>
    );
};

export default BlogDetail;
