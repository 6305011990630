import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";
import { Provider } from 'mobx-react';
import { HashRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
	  <HashRouter>

	<Provider >
    
      <App />

      <WhatsAppWidget
			phoneNo="+905300974816"
			position="right"
			widgetWidth="300px"
			widgetWidthMobile="260px"
			autoOpen={false}
			autoOpenTimer={5000}
			messageBox={true}
			messageBoxTxt="Merhaba, bize ne sormak istersiniz?"
			iconSize="40"
			iconColor="white"
			iconBgColor="green"
			headerIcon="https:/demiryildizadmin.stechomeyazilim.com/Uploads/logo.png"
			headerIconColor="#c5aa77"
			headerTxtColor="white"
			headerBgColor="#232354"
			headerTitle="Epilepsi Eğiitim"
			headerCaption="Online"
			bodyBgColor="#bbb"
			chatPersonName="Destek"
			chatMessage={<>Merhaba 👋 <br /><br /> Size Nasıl Yardımcı Olabilirim?</>}
			footerBgColor="#999"
			placeholder="Mesaj Türü.."
			btnBgColor="#478eb9"
			btnTxt="Konuşmaya Başla"
			btnTxtColor="white"
		/>
    
	</Provider>
	</HashRouter>
  </React.StrictMode>
);

