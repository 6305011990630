import React, { useEffect, useState } from 'react'
import Navbar from "../Navbar2";
import { Link } from 'react-router-dom';
import Card from './Card';
import Footer from "../Footer";
import { Breadcrumbs } from "@material-tailwind/react";
import axios from 'axios';

const Courses = (props) => {
    const [getDataPoolComplated, setDataPoolComplated] = useState([]);

    useEffect(() => {
        if(localStorage.getItem("dataLogin")!= null){
            let userID =  JSON.parse(localStorage.getItem("dataLogin")).ID

            console.log("data123123",userID)
            axios.get(`https://getjob.stechomeyazilim.info:5101/getUsersComplatedPool/select/${userID}`)
                .then(res => {
                    setDataPoolComplated(res.data.value);
                })
                .catch(err => console.log(err));
        }

    }, []);



    return (
        <div className='w-full h-full'>
            <Navbar />
            <div className="w-full  flex flex-col">

            <div className="  max-w-full mt-36  flex flex-col h-full p-10  bg-gradient-to-r from-[#d03730] via-[#eb5d5f] items-center   ">
                     <h3 className=" text-4xl w-full text-center  py-3 ">
                        <span class="text-transparent bg-clip-text bg-white font-[Lato-Medium] ">Geçmiş Anketler
                        </span>
                    </h3>
                    <div className='flex justify-center'>

                        <Breadcrumbs className='bg-white'>
                            <Link href="/" className="opacity-90 text-black font-[Lato-Bold]">
                                Anasayfa
                            </Link>

                            <Link href="/markalar" className="text-black font-[Lato-Medium]">Geçmiş Anketler</Link>
                        </Breadcrumbs>
                    </div>
                </div>
         

                <div className="w-full flex  bg-[#F7F7F7] h-full  items-center  justify-center ">
                    
                    <div className='w-full p-3  self-center  justify-center  grid   xxs:gap-2 md:gap-4 xl:gap-4 xl:grid-cols-5 md:grid-cols-3 sm:grid-cols-3 xxs:grid-cols-2' >
                        {
                        getDataPoolComplated.length>0?
                        getDataPoolComplated.map(item => (
                            <Card _item={item} />
                        ))
                    :<p className='text-secondcolor font-bold'>Geçmiş yanıtladığınız anket bulunmamaktadır!</p>
                    }


                    </div>
                   
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Courses