import React from "react";
import Moment from 'moment';
import { Link } from "react-router-dom";

const ProductCard = (props) => {
  console.log("klnsdflk",props._item)

return (
<div class="relative   rounded overflow-hidden shadow-lg border-2 border-[#d03730]">


<div class=" text-center justify-center items-center  bg-[#eb5d5f]">
  <p className="font-[Lato-Medium] text-white text-center p-2 ">{props._item.Education.Education_Title} </p>
  <text className="font-[Lato-Medium] text-white">{Moment(props._item.Education.Created_DateTime).fromNow()} </text>
</div>

<div  className="bg-[white] relative w-full justify-center items-center rounded-b-md text-center " >
                              <Link to={"/tamamlanmisegitimdetay/" + props._item.Education.Education_Title.replace('?', '').replace(/\s+/g, '-')} state={{ complatedData: props._item, _item:props._item.Education}} className="text-center justify-center items-center w-full p-3">
                                <text className="font-[Lato-Medium] text-center shadow-xl">İNCELE</text>
                                </Link>
                                </div>
</div>
)}
export default ProductCard;
