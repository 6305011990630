import React, { useEffect, useState }  from "react";
import { Link } from "react-router-dom";
import axios from 'axios';

import { HeartIcon } from "@heroicons/react/24/solid";
const SliderCard = (props) => {
    const [isLoading, setIsLoading] = useState(false);

 

    const [getEducationCategory, setEducationCategory] = useState([]);
    const [isFavourite, setIsFavourite] = useState(false);

    const handleFavouriteClick = () => {
        setIsFavourite(!isFavourite);
    };
    const [getCategoryData, setCategoryData] = useState([]);


    useEffect(() => {
        setIsLoading(true)
        const getCategory = async () => {

            try {
                //   setLoginIND(JSON.parse(localStorage.getItem('dataLoginCompany')).IND)                    


                axios.get(`https://getjob.stechomeyazilim.info:5101/getAdvertCategoryLG/select/`)
                    .then(res => {
                        console.log("1231ccc23", res.data.value)
                        setEducationCategory(res.data.value);

                    


                    })
                    .catch(err => console.log("err111", err));

            }
            catch (error) {
                console.log("errorccAS" + error)
            }


        }


        getCategory()
    }, []);
    return (
        <div className={ "bg-[white] w-full   justify-center items-center rounded-md relative" } >
        <button
            className={`absolute right-0 top-0 rounded-full bg-[#c8c8c8]  border-2  p-1 flex items-center gap-1 ${isFavourite ? 'text-red-500' : 'text-white'
                }`}
            onClick={handleFavouriteClick}
        >  <HeartIcon strokeWidth={1} className="w-6 " />


        </button>
        <div className="absolute left-0 top-0 bg-[#eb5d5f] rounded-bl-lg rounded-tr-lg shadow-xl">
            <text className="font-[Lato-Medium]  text-center text-white p-2">{props._item.AdvertSubCategory.AdvertSubCategoryTitle}</text>
        </div>



        <div className="w-full h-98 flex justify-center items-center rounded-t-lg">

{props._item.Advert_IsLetgo==true ?

<img
                src={props._item.Advert_MainImage}
                alt=""
                className="w-full h-80 rounded-t-lg"
            />

:
<img
                src={'https://letgoadmin.stechomeyazilim.info' + props._item.Advert_MainImage}
                alt=""
                className="w-full h-80 rounded-t-lg"
            />
}

            
        </div>
        <div className={ "justify-center flex-col items-center w-full h-12 flex p-2 bg-white " }>

            <text className="font-[Gotham-Medium] text-center text-[#494444]">{props._item.Advert_Title}</text>
        </div>
        <div className={ "justify-center flex-col items-center w-full flex p-2 bg-white " }>

            <text className="font-[Gotham-Medium] text-center  text-[#eb5d5f]">{props._item.Advert_Price} ₺</text>
            {   /*        <text className="font-[Gotham-Light] text-center text-[#494444]">{item.Advert_Location[0].Advert_Location_Dist}</text>*/}
        </div>

        <div className="bg-[#d03730] relative w-full justify-center items-center rounded-b-md text-center " >
            <Link to={"/advertdetail/" + props._item.Advert_Title.replace('?', '').replace(/\s+/g, '-')} state={{ allData: getCategoryData, _item: props._item }} className="text-center justify-center items-center w-full p-3">
                <text className="font-[Lato-Medium] text-[white] text-center shadow-xl ">DETAY</text>
            </Link>
        </div>

    </div>
    );
};

export default SliderCard;
