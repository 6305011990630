import React, { useEffect } from "react";
import Logo from "../logo2.png";
import whiteLogo from "../logo2.png";
import { AiOutlineMenu, AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import { useState } from "react";
import Autharizatiom from "./MainPage/LogIn/Autharizatiom";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import axios from "axios";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer, inject } from 'mobx-react'

import { ServerIcon } from "@heroicons/react/24/solid";



import Nav from "./Nav"
import Profile from "./Profile";

const Navbar = inject()(observer((props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState({ value: [] }); // Initialize with an empty array

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://getjob.stechomeyazilim.info:5101/getSearchAdvertLP/select/${searchText}`);
        const data = await response.json();

        setSearchResults(data);
      } catch (error) {
        console.error('Arama işlemi başarısız oldu:', error);
        setSearchResults({ value: [] }); // Set empty array in case of error
      }
    };

    if (searchText) {
      fetchData();
    }
  }, [searchText]);

  console.log(searchText, 'searchText');
  console.log(searchResults.value, 'searchResults');
  const navigate = useNavigate()

  const [nav, setNav] = useState(true);
  const handleNav = () => {
    setNav(!nav);
  };


  const [signUpModal, setsignUpModal] = useState(false);
  const [logInModal, setLogInModal] = useState(false);
  const [otpModalCheck, setOtpModal] = useState(false);
  const [registerData, setRegisterData] = useState({});


  const [checkDisplayMenu, setDisplayMenu] = useState(false);


  const hideDropdownMenu = () => {
    setDisplayMenu(!checkDisplayMenu)
    document.querySelector('#dropdown').removeEventListener('click', hideDropdownMenu);

  }


  const handleLogIn = () => {
    setLogInModal(false);
  };


  const _deleteAuth = async () => {
    localStorage.removeItem('dataLogin');
    localStorage.removeItem('dataLoginKelepir');

    //window.location.reload(false);
    navigate("/")
    _getData()

    window.location.reload(false);

  }



  const _closeAuthModal = async () => {
    setLogInModal(false)
  }


  const _closeLoginOpenSingUpModal = async () => {
    setLogInModal(false)
    setsignUpModal(true);
  }



  const openProfile = async () => {
    setProfileVisible(true);
  }

  const [getEducationCategory, setEducationCategory] = useState([]);
  const [getAdvertCategory, setAdvertCategory] = useState([]);
  const [getProfileVisible, setProfileVisible] = useState(false);
  const [getCategoryData, setCategoryData] = useState([]);

  const [loginData, setLoginData] = useState(null);



  const _closeProfileModal = async () => {
    setProfileVisible(false)
  }
  useEffect(() => {
    setIsLoading(true)
    const getCategory = async () => {

      try {
        //   setLoginIND(JSON.parse(localStorage.getItem('dataLoginCompany')).IND)                    


        axios.get(`https://getjob.stechomeyazilim.info:5101/getAdvertCategoryLG/select/`)
          .then(res => {
            console.log("1231ccc23", res.data.value)
            setEducationCategory(res.data.value);




          })
          .catch(err => console.log("err111", err));

      }
      catch (error) {
        console.log("errorccAS" + error)
      }


    }


    getCategory()
  }, []);
  useEffect(() => {
    const getCategory = async () => {

      try {
        //   setLoginIND(JSON.parse(localStorage.getItem('dataLoginCompany')).IND)                    

        axios.get(`https://getjob.stechomeyazilim.info:5101/getEducationCategory/select/`)
          .then(res => {
            console.log("123123", res.data)
            setEducationCategory(res.data.value);

          })
          .catch(err => console.log("err111", err));

      }
      catch (error) {
        console.log("errorccAS" + error)
      }


    }


    getCategory()

    const getCategory2 = async () => {

      try {
        //   setLoginIND(JSON.parse(localStorage.getItem('dataLoginCompany')).IND)                    

        axios.get(`https://getjob.stechomeyazilim.info:5101/getAdvertCategoryLG/select/`)
          .then(res => {
            console.log("123123", res.data)
            setAdvertCategory(res.data.value);

          })
          .catch(err => console.log("err111", err));

      }
      catch (error) {
        console.log("errorccAS" + error)
      }


    }


    getCategory2()


    const _getList = async () => {


      try {
        console.log("jsndfjkls",JSON.parse(localStorage.getItem('dataLogin')))
        if (JSON.parse(localStorage.getItem('dataLogin')) != null) {
          setLoginData(JSON.parse(localStorage.getItem('dataLogin')))
        }

      }
      catch (error) {
        console.log("errorccAS" + error)
      }


    }



    _getList()
  }, []);

  const _getData = () => {
    setLoginData(JSON.parse(localStorage.getItem('dataLogin')))
  }

  return (
    <div className="w-screen h-[145px] z-30 bg-white fixed  top-0">
      <div className=" z-10">
        <Nav />
      </div>

      <div className=" flex justify-between items-center w-full h-full ">
        <div className="grid grid-rows-2 w-full h-full border border-t-8 border-b-0">
          <div className="flex items-center h-[100px] justify-between">
            <Link className="flex items-center justify-center z-30 " to={"/"}>
              <img src={Logo} alt="/" className="xxs:h-10 sm:h-18 md:h-20 lg:h-28  mt-5  ml-8 shadow-2xl rounded-full" />
            </Link>

            <div className="px-10 md:hidden flex " onClick={handleNav}>
              {!nav ? (
                <AiOutlineClose size={23} />
              ) : (
                <AiOutlineMenu size={23} />
              )}
            </div>
            <div className="z-10 hidden md:flex justify-center items-center">



              {loginData == null ?
                <div className="z-10 w-full  bg-[#eb5d5f]  relative bottom-0 mt-10 flex  justify-between rounded-l-md">
                  <div className="flex">
                    <Link className="border-r-2 border-[white]" to="/iletisim">

                      <div class=" relative p-2">
                        <button >

                          <p className="font-[Lato-Medium] text-[white]">İletişim</p>

                        </button>

                      </div>

                    </Link>

                    <Link className="border-r-2 border-[white]" to="/hakkimizda">

                      <div class=" relative p-2">
                        <button onClick={() => setLogInModal(true)}>

                          <p className="font-[Lato-Medium] text-[white]">Hakkımızda</p>

                        </button>

                      </div>

                    </Link>

                    <Link className="border-r-2 border-[white]" to="/sss">

                      <div class=" relative p-2">
                        <button onClick={() => setLogInModal(true)}>

                          <p className="font-[Lato-Medium] text-[white]">SSS</p>

                        </button>

                      </div>

                    </Link>



                    {/*
                    <Link className="" to="/ekibimiz">

                      <div class=" relative p-2">
                        <button onClick={() => setLogInModal(true)}>

                          <p className="font-[Gotham-Medium] text-[white]">Ekibimiz</p>

                        </button>

                      </div>

                    </Link>

*/}
                  </div>

                  <div class=" bg-[#d03730] p-2 relative">
                    <button onClick={() => setLogInModal(true)}>

                      <p className="font-[Lato-Medium] text-[white] text-[20px]">Giriş Yap</p>

                    </button>

                  </div>

                </div>
                :
                <div className="z-10 w-full h-12 bg-[#eb5d5f]    relative bottom-0 mt-7 flex items-center  justify-center">
                  <ul className="flex items-end   z-10">
                    <div class="py-2 border-r-2 border-[white] dropdown inline-block relative m">
                      <input
                        type="text"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        placeholder="Araba, Ev ve daha fazlası"
                        className="w-full px-4 py-2 focus:outline-none focus:border-blue-500 pl-10 bg-gradient-to-r from-[#d03730] via-[#eb5d5f] to-[#d03730] rounded::placeholder text-white placeholder-white"
                      />


                      <AiOutlineSearch className="w-5 h-5 text-white absolute left-3 top-1/2 transform -translate-y-1/2" />
                      {searchResults.value && searchResults.value.length > 0 ? (
                        <ul class="dropdown-menu absolute hidden bg-[#d03730] pt-1">
                          {searchResults.value.map((result) => (
                            <Link to={"/advertdetail/" + result.Advert_Title.replace('?', '').replace(/\s+/g, '-')} state={{ allData: getCategoryData, _item: result }} className="text-[#868686] relative flex flex-col justify-center items-center rounded-lg text-center "  >
                              <li class="border-b-2 text-white p-5" key={result.ID}>{result.Advert_Title}</li>
                            </Link>
                          ))}
                        </ul>

                      ) : (
                        <p> </p>
                      )}
                    </div>

                    <Link to="/">
                      <div class="py-2 border-r-2 border-[white] dropdown inline-block relative ">

                        <button class=" text-white font-semibold py-2 px-4 rounded inline-flex items-center">
                          <span class="mr-1 font-[Lato-Bold]">ANASAYFA </span>

                        </button>


                      </div>
                    </Link>
                    {/* 

                    <Link to={"/epilepsiegitim"} >

                      <div class="py-2 border-r-2 border-[white] dropdown inline-block relative ">
                        <button class=" text-white  font-[Lato-Bold] py-2 px-4 rounded inline-flex items-center">
                          <span class="mr-1">Eğitimler</span>
                          <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /> </svg>
                        </button>

                        <ul class="dropdown-menu absolute hidden bg-[#d03730] pt-1">

                          {getEducationCategory.map((item) => {
                            return (
                              <li class="border-b-2"><Link class="rounded-t text-white hover:bg-[#eb5d5f] py-2 px-4 block whitespace-no-wrap  font-[Lato-Bold]" to={"/epilepsiegitimkategori/" + item.ID} state={{ _item: item }} >{item.Education_ModuleCategory_Text}</Link></li>
                            )
                          })}
                        </ul>
                      </div>


                        </Link>*/}


                    <Link to={"/advert"} >

                      <div class="py-2 border-r-2 border-[white] dropdown inline-block relative ">
                        <button class=" text-white  font-[Lato-Bold] py-2 px-4 rounded inline-flex items-center">
                          <span class="mr-1 font-[Lato-Bold]">İlanlar</span>
                          <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /> </svg>
                        </button>

                        <ul class="dropdown-menu absolute hidden bg-[#d03730] pt-1">

                          {getAdvertCategory.map((item) => {
                            return (
                              <li class="border-b-2"><Link class="rounded-t text-white hover:bg-[#eb5d5f] py-2 px-4 block whitespace-no-wrap  font-[Lato-Bold]" to={"/advertsubcategory/" + item.ID} state={{ _item: item }} >{item.Advert_Category_Title}</Link></li>
                            )
                          })}
                        </ul>
                      </div>


                    </Link>


                    {/* 
                    <Link className="" to="/epilepsianket">

                      <div class="py-2  border-r-2 border-[white] relative">
                        <button class=" text-white  font-[Lato-Bold] py-2 px-4 rounded inline-flex items-center">
                          <span class="mr-1">Anket</span>

                        </button>

                      </div>

                    </Link>
                  */}


                    <Link className="" to="/sss">

                      <div class="py-2  border-r-2 border-[white] relative">
                        <button class=" text-white  font-[Lato-Bold] py-2 px-4 rounded inline-flex items-center">
                          <span class="mr-1">SSS</span>

                        </button>

                      </div>

                    </Link>

                    <Link className="" to="/hakkimizda">

                      <div class="py-2  border-r-2 border-[white] relative">
                        <button class=" text-white  font-[Lato-Bold] py-2 px-4 rounded inline-flex items-center">
                          <span class="mr-1">Hakkımızda</span>

                        </button>

                      </div>

                    </Link>

                    <Link className="" to="/iletisim">

                      <div class="py-2  border-r-2 border-[white] relative">
                        <button class=" text-white  font-[Lato-Bold] py-2 px-4 rounded inline-flex items-center">
                          <span class="mr-1">İletişim</span>

                        </button>

                      </div>

                    </Link>
                    {/* 
                    <div class="py-2 border-r-2 border-[white] dropdown inline-block relative ">
                      <button class=" text-white  font-[Lato-Bold] py-2 px-4 rounded inline-flex items-center">
                        <span class="mr-1">Epilepsi</span>
                        <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /> </svg>
                      </button>


                      <ul class="dropdown-menu absolute hidden bg-[#d03730] pt-1">

                        <li class="border-b-2"><Link class="rounded-t text-white hover:bg-[#eb5d5f] py-2 px-4 block whitespace-no-wrap  font-[Lato-Bold]" to="/Ekibimiz">Ekibimiz</Link></li>
                        <li class="border-b-2"><Link class="rounded-t text-white hover:bg-[#eb5d5f] py-2 px-4 block whitespace-no-wrap  font-[Lato-Bold]" to="/sss">SSS</Link>
                        </li>

                        <li class="border-b-2"><Link class="rounded-t text-white hover:bg-[#eb5d5f] py-2 px-4 block whitespace-no-wrap  font-[Lato-Bold]" to="/blog">Blog</Link>
                        </li>


                        <li class="border-b-2"><Link class="rounded-t text-white hover:bg-[#eb5d5f] py-2 px-4 block whitespace-no-wrap  font-[Lato-Bold]" to="/hakkimizda">Hakkımızda</Link>
                        </li>

                        <li class="border-b-2"><Link class="rounded-t text-white hover:bg-[#eb5d5f] py-2 px-4 block whitespace-no-wrap  font-[Lato-Bold]" to="/iletisim">İletişim</Link>
                        </li>

                      </ul>



                    </div>


                  */}



                    <div class="py-2  border-r-2 border-[white] relative">
                      <button onClick={() => openProfile(true)} class=" text-white  font-[Lato-Bold] py-2 px-4 rounded inline-flex items-center">
                        <span class="mr-1 ">Profilim</span>

                      </button>

                    </div>

                    {loginData != null ?


                      <li
                        className="py-2 pr-2  hover:bg-[#d03730] " >

                        <button onClick={() => _deleteAuth()} class=" text-white hover:text-white  font-semibold py-2 px-4 rounded inline-flex items-center">
                          <FontAwesomeIcon icon={faRightFromBracket} color={'white'} />

                        </button>


                      </li>


                      : null}
                  </ul>

                </div>
              }





            </div>


            <div
              className={
                !nav
                  ? "z-10 fixed left-0 top-0 w-[60%] md:w-[20%]  h-full border-r  bg-gradient-to-b from-[#d03730] via-[#eb5d5f] to-[#d03730] border-[#eb5d5f] z-40 ease-in-out duration-300 "
                  : "z-10 fixed left-[-100%] "
              }
            >

              <div className="border-b-2 border-b-white bg-white flex justify-center items-center ">
                <Link to={"/"} >
                  <img
                    src={whiteLogo}
                    alt="/"
                    className="w-32 "
                  />


                </Link>
              </div>
              {/* MOBİL MENÜ */}



              <Link className=" flex justify-center items-center  bg-gradient-to-r from-[#d03730] via-[#eb5d5f] to-[#FFED00] border-b-2 border-b-white" to="/">

                <a
                  className="rounded-lg text-white  font-[Lato-Bold]  hover:bg-forthcolor hover:text-white text-center p-2">
                  ANASAYFA
                </a>

              </Link>


              <Link to={"/advert"} className="flex justify-center items-center bg-gradient-to-r from-[#d03730] via-[#eb5d5f] to-[#FFED00] border-b-2 border-b-white">

                <a
                  className="rounded-lg text-white  font-[Lato-Bold]  hover:bg-forthcolor hover:text-white text-center p-2">
                  İLANLAR
                </a>

              </Link>





              <Link to="/sss" className="flex justify-center items-center bg-gradient-to-r from-[#d03730] via-[#eb5d5f] to-[#FFED00] border-b-2  border-b-white">

                <a
                  className="rounded-lg text-white  font-[Lato-Bold]  hover:bg-blue-900 hover:text-white text-center p-2">
                  SSS
                </a>

              </Link>



              <Link to="/iletisim" className="flex justify-center items-center bg-gradient-to-r from-[#d03730] via-[#eb5d5f] to-[#FFED00] border-b-2  border-b-white" >

                <a
                  className="rounded-lg text-white  font-[Lato-Bold]  hover:bg-blue-900 hover:text-white text-center p-2">
                  İLETİŞİM
                </a>

              </Link>

              <Link to="/hakkimizda" className="flex justify-center items-center  bg-gradient-to-r from-[#d03730] via-[#eb5d5f] to-[#FFED00] border-b-2 border-b-white" >

                <a
                  className="rounded-lg text-white  font-[Lato-Bold]  hover:bg-forthcolor hover:text-white text-center p-2">
                  HAKKIMIZDA
                </a>

              </Link>
              {loginData != null ?

                <button className="bg-[#d03730] text-white w-full flex justify-center items-center" to="/jobsJobSeeker" onClick={() => _deleteAuth(false)}>
                  <p className="font-[Lato-Bold] p-2">ÇIKIŞ</p>

                </button>
                : null}

              {/* MOBİL MENÜ bitiş*/}


            </div>

          </div>


        </div>
      </div>

      <Autharizatiom _closeLoginOpenSingUpModal={_closeLoginOpenSingUpModal} openAuthModalVisible={logInModal} _closeAuthModal={_closeAuthModal} handleLogIn={handleLogIn} />

      <Profile _getProfileVisible={getProfileVisible} _closeProfileModal={_closeProfileModal} />

    </div>
  );
}));

export default Navbar;

