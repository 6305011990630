import React,{useState} from 'react';
import { Routes , Route } from "react-router";
import MainPage from "./components/MainPage/MainPage";
import "./App.css";

import Blog from "./components/Blog/Blog";
import BlogDetail from "./components/Blog/BlogDetail/BlogDetail";

import Advert from "./components/CariEkstre/Advert";
import AdvertSubCategory from "./components/CariEkstre/AdvertSubCategory";

import AdvertDetail from "./components/CariEkstre/AdvertDetail";
import AdvertUser from "./components/CariEkstre/AdvertUser";
import FavoriteAdvert from "./components/CariEkstre/FavoriteAdvert";
import Epilepsiegitim from "./components/CariEkstre/Epilepsiegitim";
import EpilepsiegitimKategori from "./components/CariEkstre/EpilepsiegitimKategori";
import EpilepsiDetay from "./components/CariEkstre/EpilepsiDetay";
import EpilepsiDetayComplated from "./components/CariEkstre/EpilepsiDetayComplated";

import KVKKPage from "./components/KVKK";

import EpilepsiAnket from "./components/CariEkstre/EpilepsiAnket"

import Hakkimizda from "./components/Hakkimizda/Hakkimizda"
import UserPoolHistory from "./components/UserPoolHistory/UserPoolHistory"
import UserEducationHistory from "./components/UserPoolHistory/UserEducationHistory"

import KVKK from './KVKKModal';

import SSS from "./components/SSS/SSS";
import Team from "./components/Team/Team";

import Contact from "./components/Contact/Content";

import 'moment/locale/tr';

function App() {
  const [showKVKK, setShowKVKK] = React.useState(true);

  React.useEffect(() => {
  async function _getCheck() {
    try{    
      const KVKKCheck =  JSON.parse(localStorage.getItem('KVKKCheck'))
      if(KVKKCheck==null){
        setShowKVKK(true)
      }else{
        setShowKVKK(false)
      }
   }
   catch (error) {
     
     console.log("errorAS" + error)
   }
}

_getCheck()
}, [])
  



const _closeKVKKVisible = () => {
setShowKVKK(false)
localStorage.setItem('KVKKCheck', true);
}
  return (
    <div>
    <Routes>

       <Route path="/" element={<MainPage/>}/>

       <Route path="blog" element={<Blog/>}/>

      <Route path="blogDetay/:id" element={<BlogDetail/>}/>

      <Route path="anketgecmis" element={<UserPoolHistory/>}/>
      <Route path="egitimgecmis" element={<UserEducationHistory/>}/>

      <Route path="advert" element={<Advert/>}/>

      <Route path="favoriteadvert" element={<FavoriteAdvert/>}/>
      <Route path="advertdetail/:id" element={<AdvertDetail/>}/>
      <Route path="advertsubcategory/:id" element={<AdvertSubCategory/>}/>

      <Route path="advertuser" element={<AdvertUser/>}/>
      <Route path="epilepsiegitim" element={<Epilepsiegitim/>}/>
      <Route path="epilepsiegitimkategori/:id" element={<EpilepsiegitimKategori/>}/>
      <Route path="egitimdetay/:id" element={<EpilepsiDetay/>}/>
      <Route path="tamamlanmisegitimdetay/:id" element={<EpilepsiDetayComplated/>}/>

      

      <Route path="hakkimizda" element={<Hakkimizda/>}/>
      <Route path="sss" element={<SSS/>}/>
      <Route path="ekibimiz" element={<Team/>}/>
      <Route path="iletisim" element={<Contact/>}/>

      <Route path="epilepsianket" element={<EpilepsiAnket/>}/>
      <Route  path="/kvkk" element={<KVKKPage />} />

      
    </Routes>

{showKVKK == true ? <KVKK openKVKK={showKVKK} _closeKVKKVisible={_closeKVKKVisible}/> : null}
</div>
  );
}

export default App;
