
import React, { Fragment, useEffect, useState } from 'react'
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import Navbar from "../Navbar2";
import { Breadcrumbs } from "@material-tailwind/react";
import Footer from "../Footer";
import axios from 'axios';
import { Link } from 'react-router-dom';
export default function Example() {
    const [open, setOpen] = useState(1);

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };
    const [sss, setAuthors] = useState([]);
    useEffect(() => {

        axios.get('https://getjob.stechomeyazilim.info:5101/getSSSLG/select/')
            .then(res => {
                setAuthors(res.data.value);
            })
            .catch(err => console.log(err));
    }, []);
    console.log('dada', sss)

    return (
        <div>

            <Navbar />
            {sss != null ? <div className=" flex flex-col justify-center ">
                <div className="w-full   flex flex-col justify-center">
                    <div className="  max-w-full mt-36  flex flex-col h-full p-10 bg-gradient-to-r from-[#d03730] via-[#eb5d5f] to-[#FFED00] items-center   ">
                        <h3 className=" text-4xl w-full text-center  py-3 font-[Gotham-Medium]">
                            <span class="text-transparent bg-clip-text bg-white font-[Gotham-Medium]">SIKÇA SORULAN SORULAR
                            </span>
                        </h3>
                        <div className='flex justify-center'>

                            <Breadcrumbs className='bg-white'>
                                <Link to="/" className="opacity-90 text-black font-[Lato-Bold]">
                                    Anasayfa
                                </Link>

                                <Link to="/sss" className="opacity-90 text-black font-[Lato-Medium]">SSS</Link>
                            </Breadcrumbs>
                        </div>
                    </div>

                    <div className='self-center justify-center items-center  w-3/4' >


                        <Fragment>


                            {sss.map(item => (
                                <Accordion className='m-5' open={open === item.ID}>
                                    <AccordionHeader className=' bg-[#d03730] font-[Lato-Bold]  text-white p-5' onClick={() => handleOpen(item.ID)}>
                                        {item.SSS_Title}
                                    </AccordionHeader>
                                    <AccordionBody className="rounded-b-lg  bg-[#eb5d5f] font-[Lato-Medium] p-5  text-white">
                                        {item.SSS_Desc}
                                    </AccordionBody>
                                </Accordion>
                            ))}
                        </Fragment>
                    </div>

                </div>
            </div> : null}





            <Footer />

        </div>
    );
}