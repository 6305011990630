import React, { useEffect, useState } from "react";
import Navbar from "../Navbar2";
import viewImg1 from "../../assets/Blog/viewImage.svg";

import BlogCard from "./BlogCard";
import Footer from "../Footer";
import axios from 'axios';
import { Link } from "react-router-dom";
import moment from "moment";

const Blog = () => {
  const [getData, setData] = useState([]);

  useEffect(() => {

    axios.get('https://getjob.stechomeyazilim.info:5101/getBlogEpilepsi/select/')
      .then(res => {
        setData(res.data.value);
        console.log("ldnsflkg",res.data.value)
      })
      .catch(err => console.log(err));
  }, []);

  
  return (
    <div>
      <Navbar />
      <div className="w-full">
        <div className="w-full mt-36 justify-center items-center   bg-[#F7F7F7] h-full flex flex-col">
          <h3 className="w-full text-center text-xl text-[#204686]  font-[Lato-Bold]">
            Blog Yazıları
          </h3>
          <div className="sm:w-full h-full lg:w-3/5 md:w-1/2 p-2  items-center">
          {getData.slice(0,1).map((item) => (<div className="md:w-full w-1/2  flex items-center justify-center  flex-col mx-auto border rounded-3xl shadow-xl mb-3 ">
              <img src={'https://epilepsiadmin.stechomeyazilim.info' + item.Blog_Image} alt="" className="rounded-t-3xl " />
              <div className="w-full flex flex-col">
                <div className="p-4 text-start">
                  <h1 className="text-2xl text-[Lato-Bold]">{item.Blog_Title}</h1>
                  <h1 className="text-2xl text-[Lato-Bold]">{item.Blog_ShortDesc}</h1>
                </div>
                <div className="w-full flex flex-row justify-between">
                  <p className="pl-4 pb-4 pt-2">{moment(item.Blog_CreatedDateTime).format('YYYY-MM-DD')}</p>
                  <div className="flex  items-center  justify-center my-auto pr-6 ">
                    <Link to={"/blogDetay/" + item.Blog_Title.replace('?', '').replace(/\s+/g, '-')} state={{ allBlog: getData, _item:item}} className=" w-full h-full  bg-[#d03730] flex justify-center items-center px-4 py-1 rounded-lg hover:bg-[#F2B129] ">
                      <p className="text-white text-[Lato-Bold]">Detay</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>))}
            {getData.length > 0 ? 
            <div className="md:w-full w-1/2 flex md:flex-row mx-auto flex-col ">
            {getData.map((item) => (
              <BlogCard _item={item} />
           ))} 
            
          </div>: null}
          </div>
        
        </div>
      </div>

      <Footer/>
    </div>
  );
};

export default Blog;
