
import React, { useEffect, useState } from "react";
import axios from 'axios';
import Navbar from "../Navbar2";
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumbs, Input, Button } from "@material-tailwind/react";
import Footer from "../Footer";
import LoadingSpinner from "../../LoadingSpinner";

import { HeartIcon } from "@heroicons/react/24/solid";

export default function TabsComponent() {
    const [isLoading, setIsLoading] = useState(false);

    const [isFavourite, setIsFavourite] = useState(false);
    const location = useLocation();
    const state = location.state;
    const handleFavouriteClick = () => {
        setIsFavourite(!isFavourite);
    };
    const [getEducationCategory, setEducationCategory] = useState([]);
    const [getCategoryData, setCategoryData] = useState([]);


    useEffect(() => {
        setIsLoading(true)
        const getCategory = async () => {

            try {
                //   setLoginIND(JSON.parse(localStorage.getItem('dataLoginCompany')).IND)                    


                axios.get(`https://getjob.stechomeyazilim.info:5101/getListAdvertSubCatLG/select/${state._item.ID}`)
                    .then(res => {
                        console.log("1231ccc23", res.data.value)
                        setEducationCategory(res.data.value);

                        _getCategoryData(res.data.value[0].ID, res.data.value[0].Advert_Category_Title)


                    })
                    .catch(err => console.log("err111", err));

            }
            catch (error) {
                console.log("errorccAS" + error)
            }


        }


        getCategory()
    }, []);

    const [getSelectCategory, setSelectCategory] = useState([]);
    const [getSelectName, setSelectName] = useState("");

    function _getCategoryData(ID, name) {
        let userID = JSON.parse(localStorage.getItem("dataLogin")).ID

        console.log()
        axios.get(`https://getjob.stechomeyazilim.info:5101/getCategoryAdvert/select/${ID}`)
            .then(res => {
                setSelectName()

                setSelectCategory(ID)
                setCategoryData(res.data.value)

                console.log("ksşdfkmlşdcsf", res.data.value)

                setIsLoading(false)


            })
            .catch(err => console.log(err));
    }



    return (
        <div className="w-full h-full bg-[#e9e9e9]">

            <Navbar />
            <div className="mt-36  flex flex-col h-full p-10  bg-gradient-to-r from-[#d03730] to-[#eb5d5f] items-center   ">
                <h3 className=" text-4xl w-full text-center  py-3 ">
                    <span class="text-transparent bg-clip-text bg-white  font-[Gotham-Medium]">KELEPİR İLANLAR
                    </span>
                </h3>
                <div className='flex justify-center'>

                    <Breadcrumbs className='bg-white'>
                        <Link to="/" className="opacity-90 text-black  font-[Gotham-Medium]">
                            Anasayfa
                        </Link>

                        <Link href="/Advert" className="text-black  font-[Gotham-Medium]"> 
                        
                         
                    {getEducationCategory.map((item) => {
                        return (
              
                                item.Advert_Category_Title
                         
                        )
                    })
                    }
                           </Link>
                    </Breadcrumbs>
                </div>
            </div>



            <div className="w-full justify-center items-center">






                <div className="items-center grid xxs:grid-cols-2 lg:grid-cols-3 md:grid-cols-3 gap-2 m-2 p-5 ">
                    {getCategoryData.map((item) => {
                        return (
                            <div className={"bg-[white] w-full   justify-center items-center rounded-md relative"} >
                                <button
                                    className={`absolute right-0 top-0 rounded-full bg-[#c8c8c8]  border-2  p-1 flex items-center gap-1 ${isFavourite ? 'text-red-500' : 'text-white'
                                        }`}
                                    onClick={handleFavouriteClick}
                                >  <HeartIcon strokeWidth={1} className="w-6 " />


                                </button>
                                <div className="absolute left-0 top-0 bg-[#eb5d5f] rounded-bl-lg rounded-tr-lg shadow-xl">
                                    <text className="font-[Lato-Medium]  text-center text-white p-2">{item.AdvertSubCategory.AdvertSubCategoryTitle}</text>
                                </div>



                                <div className="w-full h-98 flex justify-center items-center rounded-t-lg">

                                {item.Advert_IsLetgo==false ?
                                    <img
                                        src={'https://letgoadmin.stechomeyazilim.info' + item.Advert_MainImage}
                                        alt=""
                                        className="w-full h-80 rounded-t-lg"
                                    />:
                                    <img
                                        src={ item.Advert_MainImage}
                                        alt=""
                                        className="w-full h-80 rounded-t-lg"
                                    /> 
                                    }
                                </div>
                                <div className={"justify-center flex-col items-center w-full flex p-2 bg-white "}>

                                    <text className="font-[Gotham-Medium] text-center text-[#494444]">{item.Advert_Title}</text>
                                </div>
                                <div className={"justify-center flex-col items-center w-full flex p-2 bg-white "}>

                                    <text className="font-[Gotham-Medium] text-center  text-[#eb5d5f]">{item.Advert_Price} ₺</text>
                                    {   /*        <text className="font-[Gotham-Light] text-center text-[#494444]">{item.Advert_Location[0].Advert_Location_Dist}</text>*/}
                                </div>

                                <div className="bg-[#d03730] relative w-full justify-center items-center rounded-b-md text-center " >
                                    <Link to={"/advertdetail/" + item.Advert_Title.replace('?', '').replace(/\s+/g, '-')} state={{ allData: getCategoryData, _item: item }} className="text-center justify-center items-center w-full p-3">
                                        <text className="font-[Lato-Medium] text-[white] text-center shadow-xl ">DETAY</text>
                                    </Link>
                                </div>

                            </div>)
                    })
                    }
                </div>

                <Footer />


            </div>

            <LoadingSpinner show={isLoading} />

        </div>
    );
}