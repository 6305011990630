import React, { Component } from 'react';

import logo from './logo2.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';


const style = {

  position: "fixed",
  overflow: "hidden",
  zIndex: 1,
  background:'white',
  bottom: '1%',
  left: '150',
  border: '1px solid #000',
  boxShadow: 24,
};

class ContentImage extends Component {

  constructor(props) {
      super(props)
      this.state = {
          open: false,
          recipesData:null,
          certifica:[],
          imageVisible:false
      }
  }

  

   _closeKvkk = () => {
    localStorage.setItem('KVKKCheck', true);
  }

 


render(){

  return (
<div style={style}>

<div className='flex p-1'>
  <div className='border-[1px] border-[#eb5d5f] rounded-full flex w-14'>
  <img src={logo} alt="" className='w-full'/>
  </div>

<div className='grid p-1'>
<text className='font-[Lato-Medium]'>Hizmet kalitesi için çerezleri kullanabiliriz.</text>
<text className='font-[Lato-Regular]'>Kelepir İlanlar'a girerek kullanım izni vermiş sayılırsınız.</text>

<button onClick={()=> this.props._closeKVKKVisible()} className='bg-gradient-to-r from-[#eb5d5f] to-[#d03730] hover:from-[#d03730] hover:to-[#eb5d5f] absolute right-0 top-0 rounded-bl-md'>
<FontAwesomeIcon className="m-1"  color='white' size="lg" icon={faClose}/>
</button>
</div>
</div>

<div  className='flex w-full'>
<button onClick={()=> this.props._closeKVKKVisible()} className='bg-gradient-to-r from-[#eb5d5f] to-[#d03730] hover:from-[#d03730] hover:to-[#eb5d5f] w-full m-1 rounded-md'><text  className='font-[PoppinsReg] text-white p-1 text-[16px]'>Anladım</text></button>
<Link to={"/kvkk"} className='bg-[#e7e7e7] w-full m-1 rounded-md justify-center items-center text-center'><text  className='font-[Lato-Medium] p-1 text-[13px] text-center'>Veri Politikamız</text></Link>
</div>

</div>
  )
}
};

export default ContentImage;
