import React, { useEffect, useState } from 'react'
import Navbar from "../Navbar2";
import { Link } from 'react-router-dom';
import Footer from "../Footer";
import { Breadcrumbs } from "@material-tailwind/react";
import axios from 'axios';
import { MapPinIcon, PhoneIcon, EnvelopeIcon, SignalIcon } from "@heroicons/react/24/solid";
import ReactHtml from 'raw-html-react';
import base64 from 'react-native-base64'
import LoadingSpinner from "../../LoadingSpinner";
import utf8 from "utf8";

const Hakkimizda = () => {
    const [getTeam, setTeam] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {

        axios.get('https://getjob.stechomeyazilim.info:5101/getTeamEpilepsi/select/')
            .then(res => {
                setTeam(res.data.value)
                setLoading(false)
            })
            .catch(err => console.log(err));
    }, []);


    return (
        <div >
            <Navbar />
           { getTeam != null ? <div className=" flex flex-col justify-center ">

                <div className="  max-w-full mt-36  flex flex-col h-full p-10  bg-gradient-to-r from-[#d03730] to-[#eb5d5f] items-center   ">
                    <h3 className=" text-4xl w-full text-center  py-3 ">
                        <span class="text-transparent bg-clip-text bg-white font-[Gotham-Medium]">Hakkımızda
                        </span>
                    </h3>
                    <div className='flex justify-center'>

                        <Breadcrumbs className='bg-white'>
                            <Link to="/" className="opacity-90 text-black font-[Gotham-Medium]">
                                Anasayfa
                            </Link>

                            <Link href="/hakkimizda" className="text-black font-[Gotham-Medium]" >Ekibimiz</Link>
                        </Breadcrumbs>
                    </div>
                </div>


              <div className='grid grid-cols-3 m-2'>
                {getTeam.map((item,index) => (
                <div className="m-2 items-center border-2 rounded-md flex">

                       
                        <div className=" h-full w-full items-center rounded-xl  bg-gradient-to-r from-[#d03730] to-[#eb5d5f] ">
                       
                            <div className="flex  justify-center  w-full  bg-[white] rounded-md">
                                <p className="text-[gray] p-2 font-[Gotham-Medium] ">
                                    {item.Team_NameSurname}
                                </p>
                            </div>

   
                            <div className="justify-center  w-full items-center flex p-2">
                            <img src= {'https://epilepsiadmin.stechomeyazilim.info' + item.Team_Photo} alt="" className="h-36 rounded-xl" />
                            </div>


                            <div className="flex flex-wrap justify-center  w-full  bg-[#eb5d5f] ">
                                <a target='blank' href={item.Team_CvLink} className="p-2 text-white font-[Gotham-Medium]  text-start">
                                    CV İncele 
                                </a>
                        </div>

                    
                        {item.Team_Desc != null ? <div className="text-center flex w-full bg-white justify-center items-center rounded-b-md p-2">
                                <ReactHtml  html= {utf8.decode(base64.decode(base64.decode(item.Team_Desc)))}  />
                        </div> : null}


                    </div>

               

                </div>
                ))}
                </div>


                <Footer />

            </div> : null }

            <LoadingSpinner show={isLoading}/> 

        </div>
    )
}




export default Hakkimizda;


