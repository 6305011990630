import React from "react";
import Navbar from "../../Navbar2";

import ProfilePic from "../../../assets/BlogDetail/ProfilePic.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import { FreeMode ,Pagination, Navigation } from "swiper";
import BlogCard from "../BlogCard";
import Footer from "../../Footer";
import { useLocation, Link } from "react-router-dom";
import ReactHtml from 'raw-html-react';
import base64 from 'react-native-base64'
import utf8 from "utf8";

const BlogDetail = () => {
  const location = useLocation();
  const state = location.state;

  React.useEffect(() => {
    window.scrollTo(0, 0);
  })
  return (
    <div>
      <Navbar />
      <div className="w-full flex flex-col">
        <div className="max-w-full mt-36 flex flex-col bg-[#F7F7F7] h-full  items-center  ">
        
          <div className="w-5/6 h-full lg:w-3/5 md:w-4/5 p-2 items-center flex flex-col">
            <div className="flex flex-wrap justify-center items-center sm:px-24 pb-4">
              <h2 className="text-4xl font-[Lato-Bold] text-center ">
              {state._item.Blog_Title}
              </h2>
            </div>
            
            <div>
              <img src={'https://epilepsiadmin.stechomeyazilim.info' + state._item.Blog_Image} alt="" />
            </div>

            {state._item.Blog_Desc!= null ? <div className="flex flex-wrap justify-center items-center my-3 pb-4 ">

            <ReactHtml html= {utf8.decode(base64.decode(base64.decode(state._item.Blog_Desc)))} />
             
            </div> : null}

            <div className="grid grid-rows-2 md:grid-cols-2 gap-4 pt-8 mt-4">
              <div className="border-l-8 border-[#F2B129] px-3">
                <p className="ml-2 text-[11px]">
                {state._item.Blog_ShortDesc}
                </p>
              </div>
              <div className="border-l-8 border-[#F2B129] px-3 ">
                <p className="ml-2 text-[11px]">
                {state._item.Blog_ShortDesc}
                </p>
              </div>
            </div>
         
          
         
            <div className="w-full flex flex-wrap pt-4 pr-4 pb-12 ">
              <img src={ProfilePic} alt="" className="w-16 h-16 rounded-full" />
              <div className="flex flex-col flex-wrap py-1 justify-center pl-4">
                <h3 className="text-xl font-[Lato-Medium]">Aylin Yurtsever</h3>
                <p className="text-[11px] font-[Lato-Regular]">Getjob’s Writer</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col  bg-[#F2F2F2]">
        <div className="w-5/6 h-full lg:w-3/5 md:w-4/5 p-2 items-center flex flex-col mx-auto  py-16">
          <div className="w-full flex py-4">
            <h2 className="text-[#204686] ">Diğer Blog Yazıları</h2>
          </div>
          <div className="w-full flex">
          <Swiper
          freeMode={true}
          grabCursor={true}
          modules={[FreeMode, Pagination, Navigation]}
          className="mySwiper"
          pagination={{
            clickable: true,
          }}
          navigation={true}
          loop={true}
          loopFillGroupWithBlank={true}
          breakpoints={{
            480: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            780: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 15,
            },
            
            
          }}
        >
            {state.allBlog.slice(0,5).map((item) =>(
              <SwiperSlide>
                <BlogCard _item={item} />
              </SwiperSlide>))
              }
            
            </Swiper>
          </div>
        </div>
        <Footer/>
      </div>
    </div>
  );
};

export default BlogDetail;
